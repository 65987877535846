import React, { Component, Fragment } from "react";
import { Tooltip } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";

class GeoFlag extends Component {
  render() {
    const { name, intl } = this.props;
    let { geo } = this.props;
    if ((geo == null) || (geo == '')) geo = 'local';
    return <span>
      <Fragment>
        <Tooltip title={intl.formatMessage({ id: `country.${geo}` })}>
          <img className='geo-flag' src={`/img/flags/${geo=='local'?'default':geo.toLowerCase()}.png`} />
        </Tooltip>
        &nbsp;&nbsp;&nbsp;{name}
      </Fragment>
    </span>
  }
}

GeoFlag.defaultProps = {
  name: null,
  geo: "local",
}

export default injectIntl(GeoFlag);