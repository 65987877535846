import React, { Component, Fragment } from "react";
import { DraggableModal } from "/bbui/components/";
import { Form, Input, Select, Button, message } from "antd";
import AxiosFormData from "/helpers/AxiosFormData";
import Axios from "axios";
import { injectIntl, FormattedMessage } from "react-intl";
import { ajaxUrl } from "/bbui/config/constants";
import { product } from "/config/constants";
const package_version = 'app';
const Option = Select.Option;

class AddressGroupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lockForm: false,
      message: null,
      name: '',
      addressGroup: props.addressGroup,
      _getSelect2Ipv4: [],
      _getSelect2Ipv6: [],
      _getSelect2Mac: [],
      _getSelect2Fqdn: [],
      titlePrefix: '',
    };
  }

  componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {

			const { id, type, intl } = this.props;
			if (id) {
				this.setState({ lockForm: true });
				Axios.post(ajaxUrl[package_version].objects, AxiosFormData({ act: "getObjectGroup", type, id })).then((res) => {
					if (res.data) {
            let addressGroup = res.data[0]
						this.setState({
              addressGroup: {
                lockForm: false,
								id: addressGroup.id,
								name: addressGroup.name,
								description: addressGroup.desc,
								list: JSON.parse(addressGroup.id_objs),
                type: addressGroup.type,
                agg: addressGroup.agg,
                gsmid: addressGroup.gsmid
							}
						}, () => {
              this.setState({ lockForm: false });
						});
					}
				});
      }

      this.state.titlePrefix = intl.formatMessage({id: 'common.edit'});
      this._getSelect2Ipv4();
      this._getSelect2Ipv6();
      this._getSelect2Mac();
      this._getSelect2Fqdn();
		}
		if (this.props.addressGroup !== prevProps.addressGroup) {
			this.setState({
				lockForm: false,
				addressGroup: this.props.addressGroup,
			})
		}
	}

  handleSave = () => {
    const { intl, form } = this.props;
    const { addressGroup } = this.state;
    this.setState({ loading: true, lockForm: true });

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { group } = this.props;
        const bodyData = new FormData();
        let table = values["obj_addr_group-agg"];
        if (['ip', 'ipv6', 'fqdn'].includes(values["obj_addr_group-agg"])) table = 'addr';

        bodyData.set("act", "saveObjectsGroups");
        bodyData.set("table", table);
        bodyData.set(`obj_${table}_group-type`, 'group');
        bodyData.set(`obj_${table}_group-agg`, values["obj_addr_group-agg"]);
        bodyData.set(`obj_${table}_group-name`, values["obj_addr_group-name"]);
        bodyData.set(`obj_${table}_group-desc`, values["obj_addr_group-desc"]);
        bodyData.set(`obj_${table}_group-list`, values["obj_addr_group-list"]);

        if (group && "id" in group) {
          bodyData.set(`obj_${table}_group-id`, group.id);
        } else if (addressGroup && addressGroup.id) {
          bodyData.set(`obj_${table}_group-id`, addressGroup.id);
        }

        Axios.post(ajaxUrl[package_version].objects, bodyData).then((res) => {

          this.setState({ loading: false, lockForm: false });
          if (product == "GSM" && Array.isArray(res.data) && res.data.length == 2)
            if (res.data[1]['errorcode'] == '001') {
              this.props.form.resetFields();
              this.handleCancel();
              return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
            }

            if (res.data === "Seu login não permite acesso a esta area." || res.data === "Your login does not allow access to this area.") {
            this.props.form.resetFields();
            this.handleCancel();
            return message.error(this.props.intl.formatMessage({ id: "common.dont.permission.edit" }));
          } else if (res.data === "High Availabilty Habilitado - Estado: Backup - Acesso Bloqueado." || res.data === "High Availabilty Enabled - State: Backup - Blocked Access.") {
            this.props.form.resetFields();
            this.handleCancel(); 
            return message.error(this.props.intl.formatMessage({ id: "common.blocked.ha" }));
          }


          if (res.data[0].success == 1) {
            message.success(intl.formatMessage({ id: "objects.address.modal.save.success" }));
            this.props.form.resetFields();
            this.handleCancel();
            this.props.onSuccess();
          } else {

            res.data.forEach(erro => {
              if (erro.field == "obj_mac_group-name")
                erro.field = "obj_addr_group-name"

              if (erro.field == "obj_mac_group-desc")
                erro.field = "obj_addr_group-desc"

              form.setFields({
                [erro.field]: {
                  value: values[erro.field],
                  errors: [new Error(intl.formatMessage({ id: "common.invalid.value" }))],
                },
              });

              if (res.data[0]['exists']) {
                form.setFields({
                  'obj_addr_group-name': {
                    value: form.getFieldValue('obj_addr_group-name'),
                    errors: [new Error(this.props.intl.formatMessage({ id: "common.alreadyExists" }))]
                  }
                })
              }
            })
            message.error(intl.formatMessage({ id: "objects.actions.create.error" }));
          }
        });
      } else {
        this.setState({ lockForm: false });
      }
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    this.props.form.resetFields();
    onCancel();
  }

  renderGroup(type) {
    if (type == 'ip') return this.renderAddrV4()
    if (type == 'ipv6') return this.renderAddrV6()
    if (type == 'mac') return this.renderMac()
    if (type == 'fqdn') return this.renderFQDN()
  }

  _getSelect2Ipv4 = () => {
    const formData = new FormData();
    formData.set("act", "_getSelect2Addr");
    formData.set("unique", 1);

    Axios.post(ajaxUrl[package_version].objects_templates, formData).then((res) => {
      this.setState({ _getSelect2Ipv4: res.data.items });
    });
  }

  _getSelect2Ipv6 = () => {
    const formData = new FormData();
    formData.set("act", "_getSelect2Addrv6");
    formData.set("unique", 1);

    Axios.post(ajaxUrl[package_version].objects_templates, formData).then((res) => {
      this.setState({ _getSelect2Ipv6: res.data.items });
    });
  }

  _getSelect2Mac = () => {
    const formData = new FormData();
    formData.set("act", "_getSelect2Mac");
    formData.set("unique", 1);

    Axios.post(ajaxUrl[package_version].objects_templates, formData).then((res) => {
      this.setState({ _getSelect2Mac: res.data.items });
    });
  }

  _getSelect2Fqdn = () => {
    const formData = new FormData();
    formData.set("act", "_getSelect2Fqdn");
    formData.set("unique", 1);

    Axios.post(ajaxUrl[package_version].objects_templates, formData).then((res) => {
      this.setState({ _getSelect2Fqdn: res.data.items });
    });
  }

  renderAddrV4() {
    if(this.props._getSelect2Ipv4){
      const { _getSelect2Ipv4 } = this.props;
      if (_getSelect2Ipv4){
        return _getSelect2Ipv4.map((g) => {
          return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
        });
      }
    } 
    if (this.state._getSelect2Ipv4) {
      const {_getSelect2Ipv4} = this.state;
      return _getSelect2Ipv4.map((g) => {
        return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
      });
    }
  }

  renderAddrV6() {
    if(this.props._getSelect2Ipv6){
      const { _getSelect2Ipv6 } = this.props;
      if (_getSelect2Ipv6){
        return _getSelect2Ipv6.map((g) => {
          return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
        });
      }
    } 
    if (this.state._getSelect2Ipv6) {
      const {_getSelect2Ipv6} = this.state;
      return _getSelect2Ipv6.map((g) => {
        return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
      });
    }
  }

  renderMac() {
    if(this.props._getSelect2Mac){
      const { _getSelect2Mac } = this.props;
      if (_getSelect2Mac){
        return _getSelect2Mac.map((g) => {
          return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
        });
      }
    } 
    if (this.state._getSelect2Mac) {
      const {_getSelect2Mac} = this.state;
      return _getSelect2Mac.map((g) => {
        return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
      });
    }
  }

  renderFQDN() {
    if(this.props._getSelect2Fqdn){
      const { _getSelect2Fqdn } = this.props;
      if (_getSelect2Fqdn){
        return _getSelect2Fqdn.map((g) => {
          return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
        });
      }
    } 
    if (this.state._getSelect2Fqdn) {
      const {_getSelect2Fqdn} = this.state;
      return _getSelect2Fqdn.map((g) => {
        return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
      });
    }
  }

  render() {
    let { intl, visible, group, titlePrefix } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { lockForm, addressGroup } = this.state;
    const { TextArea } = Input;
    const formLayout = { colon: false, style: { paddingBottom: 0 } };
    const group_list = getFieldValue('obj_addr_group-list') || [];

    let lockFormGsmUtm = lockForm;
    let controleLoadingButton = false;

    titlePrefix = titlePrefix ? titlePrefix : this.state.titlePrefix;

    if (addressGroup){
      group = addressGroup;
      group.list = group.list.map(i => parseInt(i));
    }

    let rows = [];
    if (group) {

      //Object GSM disabled
      lockFormGsmUtm = group.gsmid ? true : lockForm;
      controleLoadingButton = group.gsmid ? false : lockForm;
      
      if (group.id !== undefined) {
        rows = group.list;
      }
    } else {
      group = [];
    }
    
    let lockField = false;
    if (group.id != "") {
      lockField = true;
    }
    
    if (group_list.length > 0) {
      lockField = true;
    }

    const footerActions = [
			<Button key="cancel" onClick={this.handleCancel}>
				<FormattedMessage id="common.cancel" />
			</Button>,
			<Button loading={controleLoadingButton} disabled={lockFormGsmUtm} key="save" type="primary" onClick={this.handleSave}>
				<FormattedMessage id="common.save" />
			</Button>
		]

    return <DraggableModal
      onOk={this.handleSave}
      onCancel={this.handleCancel}
      confirmLoading={lockFormGsmUtm}
      visible={visible}
      title={<Fragment>{titlePrefix}<FormattedMessage id="objects.modal.group.title" values={{ object: "Addresses" }} /></Fragment>}
      okText={intl.formatMessage({ id: "common.save" })}
      cancelText={intl.formatMessage({ id: "common.cancel" })}
      footer={footerActions}>
      <Form id="form-address-group" layout="vertical">
        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.address.modal.form.name" })} >
          {getFieldDecorator('obj_addr_group-name', {
            initialValue: group.name,
            rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.address.modal.form.name" }) }) }]
          })(
            <Input disabled={lockFormGsmUtm} />
          )}
        </Form.Item>

        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.address.modal.form.description" })} >
          {getFieldDecorator('obj_addr_group-desc', {
            initialValue: group.description,
          })(
            <TextArea disabled={lockFormGsmUtm} />
          )}
        </Form.Item>

        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.address.modal.form.type" })}>
          {getFieldDecorator('obj_addr_group-agg', {
            initialValue: group.agg,
            rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.address.modal.form.type" }) }) }]
          })(
            <Select
              disabled={lockField || lockFormGsmUtm}
              showSearch
              placeholder={intl.formatMessage({ id: "objects.addressGroup.modal.select.type" })}
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value="ip">{intl.formatMessage({ id: "objects.address.modal.form.ip" })}</Option>
              <Option value="ipv6">{intl.formatMessage({ id: "objects.address.modal.form.ipv6" })}</Option>
              <Option value="mac">{intl.formatMessage({ id: "objects.address.modal.form.mac" })}</Option>
              <Option value="fqdn">{intl.formatMessage({ id: "objects.address.modal.form.fqdn" })}</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.addressGroup.modal.object" })}>
          {getFieldDecorator('obj_addr_group-list', {
            initialValue: rows,
            rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.addressGroup.modal.object" }) }) }]
          })(
            <Select
              disabled={lockFormGsmUtm}
              style={{ width: "100%" }}
              showSearch
              placeholder={intl.formatMessage({ id: "common.select" })}
              optionFilterProp="children"
              mode="multiple"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {this.renderGroup(getFieldValue('obj_addr_group-agg'))}
            </Select>
          )}
        </Form.Item>
      </Form >
    </DraggableModal>
  }
}

AddressGroupModal.defaultProps = {
  onCancel: null,
  visible: false
}

export default injectIntl(Form.create({})(AddressGroupModal));