/**
 * Filter Action Button
 * @version 1.0
 *
 * @param {object} form
 * @param {object} onSubmit
 * @param {object} filterForm
 */

import React from 'react'
import { Button, Popover, Input, Form } from 'antd'

const renderFilterContent = ({onFilter}) => {
  const { Search } = Input;
  return <div>
    <Form>
      <Search onSearch={ value => onFilter(value) } allowClear enterButton style={{ width: "300px" }} />
    </Form>
  </div>;
}

const ActionFilter = (props) => (
  <Popover placement='bottomRight' content={renderFilterContent(props)} trigger='click'>
    <Button className='bbui-action-button'>
      <i className='fa fa-search' />
    </Button>
  </Popover>
)

export default ActionFilter
