import React, { Component, Fragment } from "react";
import { Form, Spin } from "antd";
import DraggableModal from "./DraggableModal";
import Tab from "./Tab";
import FormCore from "./FormCore";

/**
 * Brings a form inside a modal, it's intended to be used on quick forms in datagrids to update/create objects
 * 
 * ADVICE: TO USE THIS COMPONENT IT'S REQUIRED THAT YOU ADD THE TAG DraggableModalProvider IN THE ROOT OF YOUT APPLICATION
 * WRAPPING ALL THE CONTENT SO THE MODULE CAN CALCULATE THE Z-INDEX AND POSITION OF THE MODAL
 * 
 * @param { object } formConfig The form object used by the AntDesign to validate and gather the values of the form
 * @param { object } layout The fields layout, it's applied to all of them
 * @param { function } onSubmit The callback of the success submission
 * @param { boolean } visible If the modal is being shown
 * @param { object } titlePrefix The Prefix to the title (recomended: Add / Update)
 * @param { object } title The name of the element represented inside the modal ( User, group, etc... )
 * @param { string } saveText The text inside the submit button
 * @param { string } cancelText The text inside the cancel button
 * @param { function } onSave The function that handles the success submission
 * @param { function } onCancel Cancel action
 */

class FormModalTabs extends Component {
  constructor(props){
    super(props);

    this.state = { tabsErrors: [] };
  }
  /* This is an aux function just to get all the fields names
  inside all tabs to the validation mark the correct tabs  that are invalid */
  findTabFields = (field) => {
    if("child" in field){
      return this.findTabFields(field.child);
    }else if("children" in field){
      return field.children.map((item) => this.findTabFields(item)).flat();
    }

    return field.name;
  }
  setErrors = (errors, values) => {
    let fields = {};
    errors.map((error) => {
      fields[error.field] = {
        value: values[error.field],
        errors: error.errors.map((item) => new Error(item))
      };
    });
    this.props.form.setFields(fields);
  }
  onSave = () => {
    const { form, onSave } = this.props;
    const tabsState = this.state.tabs;
    this.setState({ tabsErrors: [] });
    
    form.validateFields((err, values) => {
      if (!err) {
        onSave(values, (errors) => this.setErrors(errors, values));
      }else{
        const { tabs } = this.props;
        const tabsErrors = [];
        const fieldsWithErrors = Object.keys(err);
        tabs.map((tab) => {
          const tabFields = tab.formConfig.fields.map((item) => this.findTabFields(item)).flat();
          for(let i = 0; i < fieldsWithErrors.length; i++){
            const tabIndex = tabFields.indexOf(fieldsWithErrors[i]);
            if(tabIndex > -1){
              tabsErrors.push(tabIndex);
              break;
            }
          }
        })

        this.setState({ tabsErrors });
      }
    });
  }
  renderPanes() {
    const { tabsErrors } = this.state;
    const { dataSource, form, tabs } = this.props;
    const panes = [];

    const hasDefault = tabs.filter((item) => "default" in item);

    tabs.map((item, index) => {
      let error = (tabsErrors.indexOf(index) > -1);

      if (hasDefault.length == 0 && index == 0)
        item.default = true;

      if ("children" in item && item.children != null) {
        panes.push({
          name: item.title,
          children: item.children,
          disabled: item.disabled,
          error: error,
          url: item.url,
          default: item.default
        });
      } else {
        panes.push({
          name: item.title,
          disabled: item.disabled,
          children: <FormCore noFormWrap={true} dataSource={dataSource} form={form} formConfig={item.formConfig} />,
          error: error,
          url: item.url,
          default: item.default
        });
      }
    });

    return panes;
  }
  render() {
    const {
      layout,
      bottomSpacing,
      tabPosition,
      noBox,
      type,
      footer,
      history,
      width,
      height,
      loading,
      form,
      formConfig,
      visible,
      lockForm,
      title,
      titlePrefix,
      onCancel,
      saveText,
      cancelText,
      confirmLoading,
      destroyOnClose } = this.props;

    return <DraggableModal
      visible={visible}
      title={<Fragment>{titlePrefix} {title}</Fragment>}
      onOk={this.onSave}
      onCancel={onCancel}
      okText={saveText}
      cancelText={cancelText}
      confirmLoading={confirmLoading}
      width={ width }
      height={ height }
      destroyOnClose={destroyOnClose}>
      {visible && <Spin spinning={ loading }>
        <Form onSubmit={this.onSave} layout={layout}>
          <Tab
            forceRender
            bottomSpacing={bottomSpacing}
            panes={this.renderPanes()}
            history={history}
            tabPosition={tabPosition}
            noBox={noBox}
            type={type} />
            {footer}
        </Form>
        </Spin>
      }
    </DraggableModal>
  }
}

FormModalTabs.defaultProps = {
  cancelText: "Cancel",
  dataSource: {},
  formConfig: { errors: null, fields: null, layout: null },
  height: null,
  loading: false,
  onChange: null,
  onSave: null,
  onCancel: null,
  saveText: "Save",
  title: "new registry",
  titlePrefix: "Add",
  visible: false,
  lockForm: false,
  confirmLoading: false,
  destroyOnClose: false
};

export default Form.create({ })(FormModalTabs);