export const formatBytes = (bytes, decimals) => {
  if (bytes == 0) return "0 Bytes";
  var k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const decimal = (val) => {
  var value = val + "";
  value = value.replace(/\s+/, "");

  if (!/^[\d|\.]+$/.test(value)) return 0;

  value = value.replace(/\.0$/, "");

  if (value.indexOf(".") != -1) {
    return value;
  }

  var tam = value.length;
  var temp = "";
  for (var i = tam - 1; i >= 0; i--) {
    temp = value.charAt(i) + temp;
    if (value.substr(i).length % 3 == 0 && i > 0) {
      temp = "." + temp;
    }
  }
  return temp;
};

export const formLimite = {
  int: 11, // use in max of input number
  string: 100, // use in maxlength of input text
  describe: 253, // use in maxlength of input text
  certificate: 20000, // use in maxlength of textarea
  url: 2000, // use in maxlength of input of url
  key: 2000,
  ipv4: 15,
};
