import React, { PureComponent } from "react";
import { Button, Dropdown, Menu } from "antd";

/**
 * BulkActionsMenu component
 * 
 * @param {array} actions Action buttons on the menu
 */

class BulkActionsMenu extends PureComponent {
  render() {
    const { actions, icon, lockform } = this.props;
    const renderActionsOptions = (actions) => (
      <Menu>
        {actions.map((item, index) => {
          if ("divider" in item && item.divider == true) {
            return <Menu.Divider key={`divider_${index}`} />
          } else if ("group" in item && item.group == true) {
            return (
              <Menu.ItemGroup className="bbui-bulkmenu-group" key={index} title={item.text} children={renderActionsOptions(item.children)} />
            )
          } else {
            return <Menu.Item className="bbui-bulkmenu-item" key={index} onClick={item.onClick} disabled={item.disabled}>{item.text}</Menu.Item>
          }
        })}
      </Menu>
    )

    return (
      <div style={{ float: "right" }}>
        <Dropdown disabled={lockform} placement="bottomRight" overlay={renderActionsOptions(actions)} trigger={['click']}>
          <Button className='bbui-action-button'>
            <i className={icon} />
          </Button>
        </Dropdown>
      </div>
    )
  }
}

BulkActionsMenu.defaultProps = {
  actions: null,
  icon: "fa fa-chevron-down",
  lockform: false
}
export default BulkActionsMenu;