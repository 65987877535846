/**
 * Modal used to view data JSON
 * @version 1.0
 
 */

import React, { Component } from "react";
import DraggableModal from "./DraggableModal";
import ReactJson from 'react-json-view'
import { Card, Button } from "antd";

class JsonModal extends Component {
  render() {
    const { dataSource, cancelText, onCancel, okText, title, visible, width, nodeName } = this.props;

    return <DraggableModal
      width={width}
      visible={visible}
      title={title}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      footer={[
        <Button key="back" onClick={onCancel}>{cancelText}</Button>
      ]}>

      <Card style={{ maxHeight: 500, overflowY: 'auto' }}>
        <ReactJson
          src={dataSource}
          name={nodeName}
          theme={'shapeshifter:inverted'}
          onEdit={false}
          onDelete={false}
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={false}
          collapsed={3} />
      </Card>

    </DraggableModal>
  }
}

export default JsonModal;