import React, { Component, Suspense, lazy, Fragment } from "react";
import { Icon, Row, Spin } from "antd";
import NavBar from "./components/navBar";
import SideBar from "./components/sideBar";
import { Loading } from "/bbui/components";
const Devices = lazy(() => import("/modules/devices/Devices"));
const Policies = lazy(() => import("/modules/policies/Policies"));
const Profiles = lazy(() => import("/modules/profiles/Profiles"));
const Deploys = lazy(() => import("/modules/deploys/Deploys"));
const Users = lazy(() => import("/modules/users/Users"));
const Analyzer = lazy(() => import("/modules/analytics/Analyzer"));
const Events = lazy(() => import("/bbui/layouts/analytics/events/Events"));
const Loggers = lazy(() => import("/modules/analytics/loggers/AppLoggers"));
const Reports = lazy(() => import("/bbui/layouts/analytics/reports/Reports"));
const System = lazy(() => import("/modules/system"));
const Network = lazy(() => import("/modules/network"));
const Objects = lazy(() => import("/modules/objects/Objects"));
const Administration = lazy(() => import("/modules/administration"));
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import NoMatch from "/layout/noMatch";
import Disclaimer from "/bbui/components/Disclaimer";

import Axios from "axios";

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { forceLoading: false, sidebarHidden: false };
    this.hideSidebar = this.hideSidebar.bind(this);
  }
  hideSidebar() {
    const { sidebarHidden } = this.state;
    this.setState({ sidebarHidden: !sidebarHidden });
  }


  cancelDisclaimer = () => {
    location.reload(true);
  }

  render() {
    const { forceLoading, sidebarHidden } = this.state;
    const { history, location, onLogout, disclaimerObj } = this.props;
    let style = {};
    if (sidebarHidden)
      style = { marginLeft: 0 };

    if (forceLoading) {
      return <div className="void"><Row type="flex"
        justify="space-around"
        align="middle"
        style={{ height: "100%", textAlign: "center" }}>
        <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
      </Row></div>;
    }

    return <div className="skin-black fixed pace-done" style={{ minHeight: 'cal(100vh - 72px)' }}>
      <Disclaimer
        disclaimerObj={disclaimerObj}
        handleDisclaimer={() => this.props.handleDisclaimer()}
        type="pos"
      />

      {(!disclaimerObj.enabled || disclaimerObj.valid) &&
        <Fragment>
          <NavBar onLogout={onLogout} history={history} onHideSidebar={this.hideSidebar} onLogout={this.props.onLogout} />
          <div className="wrapper row-offcanvas row-offcanvas-left">
            <SideBar hidden={sidebarHidden} location={location} />
            <aside className="right-side" style={style}>
              <Suspense fallback={<Loading loading />}>
                <section className="content">
                  <Switch>
                    <Redirect from="/" exact to="/devices" />
                    <Route path="/devices" component={Devices} />
                    <Route path="/policies" component={Policies} />
                    <Route path="/profiles" component={Profiles} />
                    <Route path="/deploys" component={Deploys} />
                    <Route path="/objects" component={Objects} />
                    <Route path="/users" component={Users} />
                    <Route path="/analyzer" component={Analyzer} />
                    <Route path="/events" exact component={Events} />
                    <Route path="/loggers" component={Loggers} />
                    <Route path="/reports" component={Reports} />
                    <Route path="/system" component={System} />
                    <Route path="/network" component={Network} />
                    <Route path="/administration" component={Administration} />
                    <Route path={"/events/:dev/:q"} exact component={Events} />
                    <Route component={NoMatch} />
                  </Switch>
                </section>
              </Suspense>
            </aside>
          </div>
        </Fragment>
      }
    </div>

  }
}

export default withRouter(AppLayout);
