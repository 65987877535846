import React, { Component } from "react";
import { Card, Col, Row } from "antd";

class NoMatch extends Component{
  render(){
    return <div className="login-bg">
      <Row type="flex" justify="space-around" align="middle" style={{ height: "100%", textAlign: "center" }}>
        <Col>
          <Card>
            <h1>
              Page not found...
            </h1>
            <p>The page you are looking for doesn"t exists or another error occurred.<br />Please contact support at <a href="mailto:support@blockbit.com">support@blockbit.com</a></p>
          </Card>
        </Col>
      </Row>
    </div>
  }
}

export default NoMatch;