/**
 * Back Action Button
 * @version 1.0
 */

import React, {PureComponent} from 'react'
import { Button, Tooltip } from 'antd'
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from "react-intl";

class ActionBack extends PureComponent {
  render() {
    const { history } = this.props;
    return (
      <Tooltip title={<FormattedMessage id="common.back" />}>
        <Button className='bbui-action-button' onClick={()=>history.goBack()}>
          <i className='fa fa-arrow-left' />
        </Button>
      </Tooltip>
    )
  }
}

export default withRouter(ActionBack)
