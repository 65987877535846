import React, { useEffect, useState } from 'react'
import { FormattedMessage } from "react-intl";
import { Modal } from 'antd';

export default function ModalPolicyConflict({ open, closeModal, conflictData }) {

  const getType = (type) => {
    switch (type) {
      case 1:
        return 'CONFLITO POR OFUSCAMENTO'
      case 2:
        return 'CONFLITO DE PARÂMETROS IGUAIS COM AÇÕES DIFERENTES'
      case 3:
        return 'CONFLITO POR DUPLICIDADE'
    }
  }

  const getTitle = '#' + conflictData.id + ' ' + conflictData.title

  return (
    <Modal
      title={getTitle}
      visible={open}
      onOk={closeModal}
      centered
      closable={false}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <p>
        <span style={{ fontWeight: 'bold' }}>
        <FormattedMessage id="policy.manager.policy.validation.modal.conclicted_with" />:
        </span> #{conflictData.conflict.id}
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>
          <FormattedMessage id="policy.manager.policy.validation.modal.type"/>:
        </span> <FormattedMessage id={`policy.manager.policy.validation.modal.type.${conflictData.conflict.type}`} />
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>
          <FormattedMessage id="policy.manager.policy.validation.modal.verified"/>:
        </span> {conflictData.conflict.date}
      </p>
      <p style={{ fontWeight: 'bold' }}>
        {conflictData.conflict.description}
      </p>
    </Modal>
  );
}
