
import React, { Component, Fragment } from "react";
import { Card, Statistic, Icon } from "antd";

class CardStatistic extends Component {
  render() {
    return <Fragment>
      <Card size="small" headStyle={{minHeight: 30, background: '#999', color: 'white'}} loading={this.props.loading} className="card-statistic" style={{ height: 90, marginBottom: 12 }}>
        <Statistic style={{ color: this.props.color, borderLeft: '8px solid ', padding: '12px 0px 0px 16px', height: 89 }} title={this.props.title} value={this.props.value} prefix={<Icon style={{ paddingRight: 8 }} type={this.props.icon} />} />
      </Card>
    </Fragment>
  }
}

CardStatistic.defaultProps = {
  title: null,
  color: '#bfbfbf',
  value: 0,
  icon: null
}

export default CardStatistic;