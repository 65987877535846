import React from "react";

/**
 * Container component
 * 
 * @param {object} children The content of the container
 */

const Container = (props) => <section className="box box-solid">{ props.children }</section>

Container.defaultProps = {
    children: null
}

export default Container;