import { injectIntl } from "react-intl";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoginLayout from "/bbui/layouts/loginLayout";
import { Button, Card, Form, Icon, Input, Row, Spin } from "antd";
import Axios from "axios";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = { invalidCode: true, loading: true, lockForm: false, showSuccess: false, token: null };
  }
  componentWillMount() {
    const token = this.props.match.params.token;

    const bodyData = new FormData();
    bodyData.append("token", token);
    bodyData.append("act", "confirmToken");

    Axios({ method: "post", url: "/ajax/login.php", data: bodyData }).then((res) => {
      if (res.data.valid) {
        this.setState({ invalidCode: false, token });
      }

      this.setState({ loading: false });
    });
  }
  handlePasswordReset = (e) => {
    e.preventDefault();
    const { intl } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ lockForm: true });
        const bodyData = new FormData();
        bodyData.append("token", this.state.token);
        bodyData.append("password", values.password);
        bodyData.append("act", "resetPasswordAction");

        Axios({ method: "post", url: "/ajax/login.php", data: bodyData }).then((res) => {
          if (Array.isArray(res.data)) {
						res.data.map(f => {
							let exists = false
              let field = f.field.split(".")[0]
              this.props.form.setFields({
                ['password']: {
                  value: '',
                  errors: [new Error(intl.formatMessage({ id: "administration.users.save.error.wakePass" }))]
                },
                ['password_confirm']: {
                  value: '',
                  errors: [new Error(intl.formatMessage({ id: "administration.users.save.error.wakePass" }))]
                },
              })
						})
						this.setState({ showSuccess: false,loading: false, lockForm: false});

          } else if (res.data.success) {
            this.setState({ showSuccess: true });
          }
        });
      }
    });
  }
  handleGoBack = () => {
    const { history } = this.props;

    history.push("/login");
  }
  validateConfirmPass = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback("The passwords dosen't match");
    } else {
      callback();
    }
  };
  validatePass = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['password_confirm'], { force: true });
    }
    callback();
  };
  render() {
    if (this.state.loading) {
      return <div className="void"><Row type="flex"
        justify="space-around"
        align="middle"
        style={{ height: "100%", textAlign: "center" }}>
        <Spin indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />} />
      </Row></div>;
    }

    const logo = require("/public/branding/img/logo.svg");
    if (this.state.invalidCode) {
      return <LoginLayout image={logo} imageAlt="Blockbit GSM" imageTitle="Powered by Blockbit">
        <Card style={{ marginTop: "20px" }} className="login-card-content">
          <p><strong>Token provided is invalid</strong></p>
        </Card>
        <Button icon="left" size="large" block ghost type="primary" onClick={this.handleGoBack}>Go back</Button>
      </LoginLayout>
    }

    if (this.state.showSuccess) {
      return <LoginLayout image={logo} imageAlt="Blockbit GSM" imageTitle="Powered by Blockbit">
        <Card style={{ marginTop: "20px" }} className="login-card-content">
          <p><strong>Password changed successfully, please proceed to the login page.</strong></p>
        </Card>
        <Button icon="left" size="large" block ghost type="primary" onClick={this.handleGoBack}>Go to login page</Button>
      </LoginLayout>
    }

    const { getFieldDecorator } = this.props.form;
    const { lockForm } = this.state;

    let fieldLock = {};
    if (lockForm)
      fieldLock = { hasFeedback: true, disabled: true, validateStatus: "validating" };

    return <LoginLayout image={logo} imageAlt="Blockbit GSM" imageTitle="Powered by Blockbit">
      <Form onSubmit={this.handlePasswordReset}>
        <Card style={{ marginTop: "20px" }} className="login-card-content">
          <p style={{ textAlign: "left" }}>To proceed please enter the new password and confirm it on the field bellow:</p>
          <Form.Item {...fieldLock}>
            {getFieldDecorator('password', { rules: [{ required: true, message: "Password is required" }, { validator: this.validatePass }] })(
              <Input size="large" disabled={lockForm} placeholder="Password" type="password" />
            )}
          </Form.Item>
          <Form.Item {...fieldLock}>
            {getFieldDecorator('password_confirm', { rules: [{ required: true, message: "Password confirmation is required" }, { validator: this.validateConfirmPass }] })(
              <Input size="large" disabled={lockForm} placeholder="Password confirmation" type="password" />
            )}
          </Form.Item>
        </Card>
        <Button size="large" disabled={lockForm != false} htmlType="submit" icon="reload" block type="primary">Reset password</Button>
      </Form>
    </LoginLayout>
  }
}

export default withRouter(Form.create({ name: 'loginForm' })(injectIntl(ResetPassword)));
