var UserProfile = (function () {
  var getName = function () {
    return localStorage.getItem('username')
  };

  var getEmail = function () {
    return localStorage.getItem('useremail');  
  };

  var getSaml = function () {
    return localStorage.getItem('saml');  
  };

  var set = function (name, mail, saml = false) {
    localStorage.setItem('username', name)
    localStorage.setItem('useremail', mail)
    localStorage.setItem('saml', saml)
  };

  return {
    getName,
    getEmail,
    getSaml,
    set
  }

})();

export default UserProfile;