/**
 * Modal used to confirm deletions
 * @version 1.0
 * 
 * @param { string } cancelText The Text displayed inside the cancel button
 * @param { string } message Confirm message
 * @param { function } onCancel Action triggered on click cancel
 * @param { function } onOk Action after the deletion is confirmed
 * @param { string } okText Text displayed inside the confirm button
 * @param { string } title Modal title
 * @param { boolean } visible 
 */

import React, { Component } from "react";
import DraggableModal from "./DraggableModal";

class ConfirmDelete extends Component {
  render() {
    const { cancelText, message, onCancel, onConfirm, okText, title, visible, confirmLoading, footer } = this.props;

    return <DraggableModal
      visible={visible}
      title={title}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      onOk={onConfirm}
      confirmLoading={confirmLoading}
      footer={footer}
    >
      {message}
    </DraggableModal>
  }
}

ConfirmDelete.defaultProps = {
  cancelText: "Cancel",
  message: "Are you sure you want to delete?",
  onCancel: null,
  onConfirm: null,
  okText: "Delete",
  title: "Are you sure?",
  visible: false,
  confirmLoading: false
}

export default ConfirmDelete;