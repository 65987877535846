import React, { Component } from "react"
import "./worldmap.css";
import { VectorMap } from "react-jvectormap";
import { formatDevicesToEvents } from "/bbui/helpers/utils";
import { Redirect } from 'react-router-dom'
import { product, getGsmPrefix } from "/config/constants";

class WorldMap2 extends Component {
  constructor(props) {
    super(props);
  }

  handleClick = (e, code) => {
    let { allowedDateType, date } = this.props;
    let data = this.formatDateToEvents(date, this.props.typeDate);

    if(product == "GSM") { allowedDateType = true; }

    if(this.props.drillDown2){
      allowedDateType ? this.redirectEvents2(this.props.drillDown, this.props.drillDown2, code, data, date.user) : false
    }else{
      allowedDateType ? this.redirectEvents(this.props.drillDown, code, data, date.user) : false
    }
  }

  formatDateToEvents = (props, type) => {
    let data = props
    if (type == "yesterday" || type == "today"  || type == "last_10m"  || type == "last_6h" || type == "last_12h" || type == "last_18h" || type == "last_7" || type == "last_30" || type == "this_month" || type == "last_month" ) {
      data = type;
    } else {
      let data_ini;
      let data_end;
      let delimiter = '-';
      data_ini = data.date_ini.split(delimiter)
      data_ini = data_ini[2] + "/" +data_ini[1] + "/" +data_ini[0]
      data_end = data.date_end.split(delimiter)
      data_end = data_end[2] + "/" +data_end[1] + "/" +data_end[0]
      data = (data_ini) + '~' + (data_end)
      data = data.replace(/\//g, "-")
    }
    return data
  }

  formatDevicesToEvents = () => {
    let device = ''
    let data = this.props.date
    device = data.device_type + "^"+data.device_id
    return device;
  }

  redirectEvents = (filter, country, date, user) => {
    
    // console.log("redirectEvents", filter, country, date);

    if(filter){
      let device = this.formatDevicesToEvents();
      let query = `${filter}:"${country}" date:"${date}" logtype:"ips"`
      let uri = '';
      query = window.encodeURIComponent(query);
      if(product == 'UTM'){
        uri = encodeURI(getGsmPrefix()+`/admin/apps/dashboard-events.php?q=${query}`)
      }else{
        uri = encodeURI(`/events/${device}/${query}`)
      }
      window.open(uri);
    }
  }

  redirectEvents2 = (filter, filter2, country, date, user) => {

    // console.log ("redirectEvents2", filter, filter2, country, date)

    if(filter){
      let device = this.formatDevicesToEvents();
      let query; 
      if(!!user) {
        query = `${filter}:"${country}" ${filter2}:"${country}" date:"${date}" logtype:"firewall" client_user:"${user}"`
      } else {
        query = `${filter}:"${country}" ${filter2}:"${country}" date:"${date}" logtype:"firewall"`
      }
      let uri = '';
      query = window.encodeURIComponent(query);
      if(product == 'UTM'){
        uri = encodeURI(getGsmPrefix()+`/admin/apps/dashboard-events.php?q=${query}`)
      }else{
        uri = encodeURI(`/events/${device}/${query}`)
      }
      window.open(uri);
    }
  }

  showInfoOnPopover = (e, el, code) => {
    const { dataSource} = this.props;
    if (dataSource[code]) {
      el.html(el.html() + " (Network Traffic - " + dataSource[code] + ")");
    }
  }
  render() {

    const { height, dataSource } = this.props;
    return (
      <VectorMap
        map={"world_mill"}
        backgroundColor="transparent"
        zoomOnScroll={true}
        containerStyle={{
          width: "100%",
          height
        }}
        containerClassName="map"
        regionStyle={{
          initial: {
            fill: "#e4e4e4",
            "fill-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0
          },
          hover: {
            "fill-opacity": 0.8,
            cursor: "pointer"
          },
          selected: {},
          selectedHover: {}
        }}
        regionsSelectable={false}
        series={{
          regions: [
            {
              values: dataSource, //this is your data
              scale: [
                "#b0c4de",
                "#c5eddf",
                "#a5d5d8",
                "#8abccf",
                "#73a2c6",
                "#5d8abd",
                "#4771b2",
                "#2e59a8",
                "#00429d"
              ],
              normalizeFunction: "polynomial"
            }
          ]
        }}
        onRegionTipShow={this.showInfoOnPopover}
        onRegionClick={this.handleClick}
      />
    )
  }
}

WorldMap2.defaultProps = {
  dataSource: {}
};

export default WorldMap2