/**
 * Componente para interface onde é exibido um modal com as versões iguais e subquentes
 * @version 1.0
 *
 * @param {boolean} visible - Seta se o modal é exibido ou não
 * @param {string} type Tipo do clone a ser feito: profiles, policy.packages, policy.templates ou device.templates
 * @param {array} item - Item que é enviado via props para saber qual a versão a ser clonada
 * @param {function} onCancel - Função chamada para cancelar a modal
 * @param {function} onConfirm - Função chamada para confirmar a modal
 */

import React, { Component, PureComponent, Fragment } from "react";
import { injectIntl } from "react-intl";
import { ConfirmDelete } from "./";
import {
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Icon,
  Button,
  Tooltip,
  message,
  Select,
} from "antd";
import { utm_versions } from "/config/constants";

const versionOptions = utm_versions("1.5", "2.4");

class CloneVersion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      versionSelected: "",
    };
  }

  componentDidMount() {
    this.setState({ versionSelected: this.props.item.version });
  }

  handleChange = (value) => {
    this.setState({ versionSelected: value });
  };

  renderSelect() {
    const { intl, item } = this.props;

    return (
      <Select
        defaultValue={item.version}
        onChange={(value) => this.handleChange(value)}
        style={{ width: "50%", paddingTop: 12 }}
        placeholder={intl.formatMessage({ id: "common.version" })}
      >
        {versionOptions
          .filter(
            (p) =>
              parseFloat(p.value) >= item.version &&
              String(item.version).split(".")[0] == p.value.split(".")[0]
          )
          .map((g) => {
            return (
              <Select.Option key={g.value} value={g.value}>
                {g.text}
              </Select.Option>
            );
          })}
      </Select>
    );
  }

  handleConfirm = () => {
    const { versionSelected } = this.state;

    this.setState({ versionSelected: "" });
    this.props.onConfirm(versionSelected);
  };

  handleCancel = () => {
    this.setState({ versionSelected: "" });
    this.props.onCancel();
  };

  render() {
    const { intl, visible, type } = this.props;
    return (
      <Fragment>
        <ConfirmDelete
          visible={visible}
          onCancel={() => this.handleCancel()}
          title={intl.formatMessage({ id: `clone.${type}` })}
          message={
            <Fragment>
              <div style={{ height: 95 }}>
                <span style={{ paddingBottom: 12 }}>
                  {intl.formatMessage({ id: `clone.confirm.message.${type}` })}
                </span>
                {this.renderSelect()}
              </div>
            </Fragment>
          }
          onConfirm={() => this.handleConfirm()}
          okText={intl.formatMessage({ id: "common.confirm.ok" })}
          cancelText={intl.formatMessage({ id: "common.cancel" })}
        />
      </Fragment>
    );
  }
}

CloneVersion.defaultProps = {};

export default injectIntl(Form.create({ name: "clone_version" })(CloneVersion));
