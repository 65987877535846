import React from "react";

/**
 * Title component
 * 
 * @param {object} children The content of the header
 */

const Header = (props) => {
    return <section className="content-header">
        <h1>{ props.children }</h1>
    </section>
}

Header.defaultProps = {
    children: null
}

export default Header;