import React, { Component, Fragment } from "react";
import {
  Datagrid,
  ConfirmDelete,
  DraggableModal,
  FormModal,
  Denied,
} from "/bbui/components/";
import {
  Button,
  Form,
  Col,
  Row,
  message,
  Tooltip,
  Input,
  Select,
  InputNumber,
  TimePicker,
  DatePicker,
  Card,
  Progress,
  Tag,
  Icon,
  Switch,
} from "antd";
import { datepicker_locale } from "/bbui/config/constants";
import { injectIntl, FormattedMessage } from "react-intl";
import { ajaxUrl } from "/config/constants";
import BackupsDevices from "./BackupsDevices";
import Axios from "axios";
import validator from "validator";
import moment from "moment";
//import { ExclamationCircleOutlined  } from '@ant-design/icons';
const package_version = "2.4";
const { Option, OptGroup } = Select;
const lang = window.localStorage.getItem("language");

class Backups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      selectedRows: [],
      selectedRowKeys: [],
      selectedBackup: [],
      loading: false,
      lockForm: false,
      denied: false,
      _getBackups: [],
      _getDevices: [],
      _getGroups: [],
      _getNFS: [],
      _getSMB: [],
      _getSFTP: [],
      _getUSB: [],
      action: "",
      filters: "",
      backupStatus: [],
      showMonitorBackup: false,
      showModalBackup: false,
      showModalStopBackup: false,
      dataSourceBackup: [],
      itemStopBackup: [],
    };
  }

  componentDidMount() {
    this._getDevicesAndGroups();
    this._getStorages();
    this._getBackups();
    this.interval = setInterval(this._getBackups, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  _getBackups = (
    page = this.state.pagination.current,
    pageSize = this.state.pagination.pageSize,
    filters = this.state.filters
  ) => {
    const bodyData = new FormData();
    bodyData.set("act", "_getBackups");
    bodyData.set("limit", pageSize);
    bodyData.set("page", page);
    bodyData.set("filter", filters);

    Axios.post(ajaxUrl[package_version].devices_backups, bodyData).then(
      (res) => {
        if (Array.isArray(res.data) && res.data.length == 2)
          if (res.data[1]["errorcode"] == "001") {
            return this.setState({ denied: true });
          }

        this.setState({ _getBackups: res.data.data });

        const pag = this.state.pagination;
        if (res.data.total[0]) {
          pag.total = res.data.total[0].total;
        }

        this.setState({ pagination: { ...pag }, loading: false });
      }
    );
  };

  _getDevicesAndGroups = () => {
    const bodyData = new FormData();
    bodyData.set("act", "_getDevicesAndGroups");

    Axios.post(ajaxUrl[package_version].devices_backups, bodyData).then(
      (res) => {
        if (Array.isArray(res.data) && res.data.length == 2)
          if (res.data[1]["errorcode"] == "001")
            return this.setState({ denied: true });

        this.setState({ _getDevices: res.data.items.device });
        this.setState({ _getGroups: res.data.items.group });
      }
    );
  };

  _getStorages = () => {
    const bodyData = new FormData();
    bodyData.set("act", "_getStorages");

    Axios.post(ajaxUrl[package_version].devices_backups, bodyData).then(
      (res) => {
        if (Array.isArray(res.data) && res.data.length == 2)
          if (res.data[1]["errorcode"] == "001")
            return this.setState({ denied: true });

        this.setState({ _getNFS: res.data.items.nfs });
        this.setState({ _getSMB: res.data.items.smb });
        this.setState({ _getSFTP: res.data.items.sftp });
        this.setState({ _getUSB: res.data.items.usb });
      }
    );
  };

  changeStatus = (item, checked) => {
    const { intl } = this.props;
    this.setState({ loading: true });
    let status = checked === true ? "enabled" : "disabled";

    const bodyData = new FormData();
    bodyData.set("act", "_changeStatusBackup");
    bodyData.set("id", item.id);
    bodyData.set("status", status);

    Axios.post(ajaxUrl[package_version].devices_backups, bodyData).then(
      (res) => {
        if (Array.isArray(res.data) && res.data.length == 2)
          if (res.data[1]["errorcode"] == "001") {
            this.setState({ loading: false });
            return message.error(
              this.props.intl.formatMessage({ id: "common.dont.permission" })
            );
          }

        if (res.data.success == "1") {
          this._getBackups();
          this.setState({ loading: false });
          return message.success(
            intl.formatMessage({
              id: `devices.backups.action.${status}.success`,
            })
          );
        } else {
          this.setState({ loading: false });
          return message.error(
            intl.formatMessage({ id: `devices.backups.action.${status}.error` })
          );
        }
      }
    );
  };

  renderActions = (data) => {
    let checked = data.data.status != "disabled" ? true : false;
    return (
      <div className="ui-datagrid-actions">
        <Tooltip
          title={this.props.intl.formatMessage({
            id: `devices.backups.action.${
              data.data.status == "disabled" ? "enabled" : "disabled"
            }`,
          })}
        >
          <Switch
            style={{ marginBottom: 3 }}
            size="small"
            checked={checked}
            onClick={(checked) => this.changeStatus(data, checked)}
          />
        </Tooltip>
        <Tooltip title={this.props.intl.formatMessage({ id: "common.edit" })}>
          <Button
            onClick={() => this.openModal(data)}
            size="small"
            className="ant-btn-icon-only"
          >
            <i className="fa fa-pencil" />
          </Button>
        </Tooltip>
        <Tooltip
          title={this.props.intl.formatMessage({
            id: "devices.backups.backup.details",
          })}
        >
          <Button
            onClick={() => this.openBackupStatus(data)}
            size="small"
            className="ant-btn-icon-only"
          >
            <i className="fa fa-list-ul" />
          </Button>
        </Tooltip>
        {/*<Tooltip title={this.props.intl.formatMessage({ id: "devices.backups.backup.stop" })}>
        <Button disabled={data.data.status == 'running' ? false : true} 
  style={{ display: 'inline', padding: 0, width: 24 }} onClick={() => this.openModalStopBackup(data)} size="small" className="ant-btn-icon-only"><i className="fa fa-stop" /></Button></Tooltip>*/}
        <Tooltip title={this.props.intl.formatMessage({ id: "common.delete" })}>
          <Button
            onClick={() => this.showDelete([data])}
            size="small"
            className="ant-btn-icon-only"
          >
            <i className="fa fa-trash" />
          </Button>
        </Tooltip>
      </div>
    );
  };

  handleChange = (pagination, filters) => {
    this.setState({ pagination, filters }, () => this._getBackups());
  };

  closeModal = () => {
    this.setState({
      loading: false,
      showModalBackup: false,
      showMonitorBackup: false,
      selectedBackup: [],
    });
    this.props.form.resetFields();
  };

  openModal = (item) => {
    const { form } = this.props;

    this._getDevicesAndGroups();

    if (item != null) {
      delete item.data.progress;
      delete item.data.status;
      item.data = {
        ...item.data,
        devices: item.data.devices.devices,
        groups: item.data.devices.groups,
      };
      form.setFieldsValue(item.data);
      this.setState({
        action: this.props.intl.formatMessage({ id: "common.edit" }),
        selectedBackup: { ...item.data, id: item.id },
        showModalBackup: true,
      });
    } else {
      this.props.form.resetFields();
      this.setState({
        action: this.props.intl.formatMessage({ id: "common.create" }),
        selectedBackup: [],
        showModalBackup: true,
      });
    }
  };

  /*_stopBackups = (item) => {
    const { intl } = this.props;
    this.setState({ loading: true })

    const bodyData = new FormData();
    bodyData.set("act", "_stopBackups");
    bodyData.set("id", item.id);
    bodyData.set("type", item.data.type);

    Axios.post(ajaxUrl[package_version].devices_backups, bodyData).then((res) => {
      if (Array.isArray(res.data) && res.data.length == 2)
        if (res.data[1]['errorcode'] == '001'){
          this.setState({ loading: false })
          return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
        }

      if (res.data.success == "1") {
        this._getBackups();
        this.hideModalStopBackup();
        this.setState({ loading: false })
        return message.success(intl.formatMessage({ id: "devices.backups.action.stop.success" }));
      } else {
        this.setState({ loading: false })
        return message.error(intl.formatMessage({ id: "devices.backups.action.stop.error" }))
      }
    });
  }*/

  showDelete = (item) => {
    this.setState({ selectedBackup: item, showDelete: true });
  };
  hideDelete = () => {
    this.setState({ selectedBackup: [], showDelete: false, lockForm: false });
  };

  handleSave = () => {
    const { form, intl } = this.props;
    const { selectedBackup, _getDevices } = this.state;

    form.validateFields((err, values) => {
      if (!err) {
        const bodyData = new FormData();

        if (values["schedule"] != "oneshot") {
          values["value_time"] = moment(values["value_time"]).format("HH:mm");
        } else {
          values["value_time"] = moment(values["value_time"]).format(
            "YYYY-MM-DD HH:mm"
          );
        }

        this.setState({ lockForm: true });
        values["devices"] = {
          devices: form.getFieldValue("devices") || [],
          groups: form.getFieldValue("groups") || [],
        };

        bodyData.set("act", "_saveBackup");
        bodyData.set("data", JSON.stringify(values));

        if (selectedBackup !== null) {
          bodyData.set("id", selectedBackup.id);
        }

        Axios.post(ajaxUrl[package_version].devices_backups, bodyData).then(
          (res) => {
            if (Array.isArray(res.data) && res.data.length == 2)
              if (res.data[1]["errorcode"] == "001") {
                form.resetFields();
                this.setState({
                  loading: false,
                  lockForm: false,
                  showModalBackup: false,
                  selectedBackup: [],
                });
                return message.error(
                  this.props.intl.formatMessage({
                    id: "common.dont.permission",
                  })
                );
              }

            if (res.data[0].success == 1) {
              message.success(
                intl.formatMessage({
                  id: "devices.backups.action.save.success",
                })
              );
              form.resetFields();
              this.setState({
                loading: false,
                lockForm: false,
                showModalBackup: false,
                selectedBackup: [],
              });
              this._getBackups();
            } else {
              this.setState({ loading: false, lockForm: false });
              res.data.forEach((error) => {
                if (error.error) {
                  form.setFields({
                    [error.error]: {
                      value: values[error.error],
                      errors: [
                        new Error(
                          intl.formatMessage({
                            id: "devices.backups.action.already.exists",
                          })
                        ),
                      ],
                    },
                  });
                } else {
                  form.setFields({
                    [error.field]: {
                      value: values[error.field],
                      errors: [
                        new Error(
                          this.props.intl.formatMessage({
                            id: "common.error.invalid.format",
                          })
                        ),
                      ],
                    },
                  });
                }
              });
            }
          }
        );
      }
    });
  };

  handleChangeSelect = (field, value) => {
    const { form } = this.props;
    form.setFieldsValue({ [field]: value });
  };

  deleteBackup = () => {
    const { selectedBackup } = this.state;
    const { intl } = this.props;

    const bodyData = new FormData();
    bodyData.set("act", "_deleteBackup");

    this.setState({ loading: true });

    if (Array.isArray(selectedBackup)) {
      bodyData.set("ids", `[${selectedBackup.map((item) => item.id)}]`);
    } else {
      bodyData.set("ids", `[${selectedBackup.id}]`);
    }

    Axios.post(ajaxUrl[package_version].devices_backups, bodyData)
      .then((res) => {
        if (Array.isArray(res.data) && res.data.length == 2)
          if (res.data[1]["errorcode"] == "001") {
            this.hideDelete();
            this.setState({ loading: false });
            return message.error(
              this.props.intl.formatMessage({
                id: "common.dont.permission.delete",
              })
            );
          }

        if (res.data.success == "1") {
          this._getBackups();
          message.success(
            intl.formatMessage({ id: "devices.backups.action.delete.success" })
          );
        } else {
          if (res.data.error == "1" && res.data.storage == "off") {
            return message.error(
              intl.formatMessage({
                id: "devices.backups.action.delete.storage.error",
              })
            );
          }
          message.error(
            intl.formatMessage({ id: "devices.backups.action.delete.error" })
          );
        }
      })
      .finally(() => {
        this.hideDelete();
        this.setState({ loading: false });
      });
  };

  openBackupStatus = (item) => {
    this.setState({ showMonitorBackup: true, dataSourceBackup: item });
  };

  openModalStopBackup = (data) => {
    this.setState({ showModalStopBackup: true, itemStopBackup: data });
  };

  hideModalStopBackup = () => {
    this.setState({ showModalStopBackup: false, itemStopBackup: [] });
  };

  renderProgress(item){
 
    let deviceErrors = item.device_errors ? item.device_errors : 0;    

    let deviceLength;

    if(!item.devices.length || item.devices.length === undefined ){
      deviceLength = item.devices.devices.length;
    }else{
      deviceLength = item.devices.length;
    }

    const total = deviceLength;
    const errors = item.device_errors;
    const success = total - errors;
    const percent = (success * 100) / total;
    
    if (item.deploy_running == true)
      return <Progress strokeColor={"red"} percent={0} />;
    return (
      <Fragment>
        {item.status == "done" && (
          <Progress strokeColor={"green"} percent={parseInt(item.progress)} />
        )}
        {item.status == "disabled" && (
          <Progress strokeColor={"gold"} percent={0} />
        )}
        {item.status == "waiting" && (
          <Progress strokeColor={"gold"} percent={parseInt(item.progress)} />
        )}
        {item.status == "downloading" && (
          <Progress strokeColor={"geekblue"} percent={66} />
        )}
        {(item.status == "running" || item.status == "restoring") && (
          <Progress strokeColor={"geekblue"} percent={33} />
        )}
        {(item.status == "error" && deviceErrors === deviceLength ) && (
          <Progress strokeColor={"red"} percent={100} status="exception" />
        )}
        {(item.status == "error" && deviceErrors < deviceLength ) && (
          <Progress strokeColor={"gold"} percent={parseInt(percent)} status={"normal"} />
        )}
      </Fragment>
    );
  }

  renderStatus(item) {

    let deviceErrors = item.data.device_errors ? item.data.device_errors : 0;    

    let deviceLength;
    if(!item.data.devices.length || item.data.devices.length === undefined ){
      deviceLength = item.data.devices.devices.length;
    }else{
      deviceLength = item.data.devices.length;
    }

    if (item.data.deploy_running == true)
      return (
        <Tag color="red">
          <Icon type="sync" spin />{" "}
          {this.props.intl.formatMessage({
            id: "devices.backups.status.deploy.running",
          })}
        </Tag>
      );

    if (item.data.status == "done")
      return (
        <Tag color="green">
          {this.props.intl.formatMessage({
            id: "devices.backups.status.success",
          })}
        </Tag>
      );

    if (item.data.status == "waiting")
      return (
        <Tag color="yellow">
          {this.props.intl.formatMessage({
            id: "devices.backups.status.waiting",
          })}
        </Tag>
      );

    if (item.data.status == "downloading")
      return (
        <Tag color="geekblue">
          <Icon type="sync" spin />{" "}
          {this.props.intl.formatMessage({
            id: "devices.backups.status.downloading",
          })}
        </Tag>
      );

    if (item.data.status == "running")
      return (
        <Tag color="geekblue">
          <Icon type="sync" spin />{" "}
          {this.props.intl.formatMessage({
            id: "devices.backups.status.running",
          })}
        </Tag>
      );

    if (item.data.status == "error" && deviceErrors === deviceLength)
      return (
        <Tag color="red">
          {this.props.intl.formatMessage({
            id: "devices.backups.status.error",
          })}
        </Tag>
      );

    if (item.data.status == "error" && deviceErrors < deviceLength)
      return (
        <Tag color="yellow">
          {this.props.intl.formatMessage({
            id: "devices.backups.status.partial",
          })}
        </Tag>
      );

    if (item.data.status == "disabled")
      return (
        <Tag color="orange">
          {this.props.intl.formatMessage({ id: "common.disabled" })}
        </Tag>
      );

    if (item.data.status == "restore" || item.data.status == "restoring")
      return (
        <Tag color="geekblue">
          <Icon type="sync" spin />{" "}
          {this.props.intl.formatMessage({
            id: "devices.backups.status.restoring",
          })}
        </Tag>
      );

    /*if (item.data.status == "stop")
      return <Tag color="orange"><ExclamationCircleOutlined style={{paddingTop: 4}} />  {this.props.intl.formatMessage({ id: "devices.backups.status.stopped" })}</Tag>*/
  }

  renderLastStatus = (item) => {
    return <Fragment>{moment(item).format("DD/MM/YYYY HH:mm")}</Fragment>;
  };

  renderNextBackup = (item) => {
    var last_backup = "";
    if (item.data.schedule == "daily") {
      var today = moment(new Date()).format("YYYY-MM-DD");
      var time_scheduled = item.data.value_time;

      // criar no moment uma data do dia + o horario agendado
      var next_backup = moment(today + " " + time_scheduled);

      if (moment(new Date()).isBefore(next_backup) == true) {
        last_backup = next_backup.format("DD/MM/YYYY HH:mm");
      } else {
        last_backup = next_backup.add(1, "days").format("DD/MM/YYYY HH:mm");
      }
    }
    if (item.data.schedule == "weekly") {
      var time_scheduled = item.data.value_time;
      var day_scheduled = parseInt(item.data.value_weekday);
      var today = moment(
        moment(new Date()).format("YYYY-MM-DD") + " " + time_scheduled
      );

      var next_backup = moment(today)
        .day(day_scheduled)
        .format("YYYY-MM-DD HH:mm");
      if (moment(new Date()).isBefore(next_backup) == true) {
        last_backup = moment(next_backup).format("DD/MM/YYYY HH:mm");
      } else {
        last_backup = moment(today)
          .day(day_scheduled + 7, "days")
          .format("DD/MM/YYYY HH:mm");
      }
    }
    if (item.data.schedule == "monthly") {
      var today = moment(new Date()).format("YYYY-MM-DD");
      var day_scheduled = parseInt(item.data.value_day);
      var hour = item.data.value_time.split(":");
      var time_scheduled = moment([
        new Date().getFullYear(),
        new Date().getMonth(),
        day_scheduled,
        hour[0],
        hour[1],
        0,
      ]).format("YYYY-MM-DD HH:mm");

      if (moment(new Date()).isBefore(time_scheduled) == true) {
        last_backup = moment(time_scheduled).format("DD/MM/YYYY HH:mm");
      } else {
        last_backup = moment(time_scheduled)
          .add(1, "months")
          .format("DD/MM/YYYY HH:mm");
      }
    }
    if (item.data.schedule == "oneshot") {
      var today = moment(new Date()).format("YYYY-MM-DD HH:mm");
      var time_scheduled = moment(item.data.value_time);

      if (moment(today).isSameOrBefore(time_scheduled) == true) {
        last_backup = time_scheduled.format("DD/MM/YYYY HH:mm");
      } else {
        last_backup = this.props.intl.formatMessage({
          id: "devices.backups.backup.not.scheduled",
        });
      }
    }
    return <Fragment>{last_backup}</Fragment>;
  };

  render() {
    const { intl } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const {
      loading,
      selectedRowKeys,
      selectedRows,
      showDelete,
      showModalBackup,
      showMonitorBackup,
      showModalStopBackup,
      lockForm,
      action,
      pagination,
      denied,
      _getBackups,
      selectedBackup,
      dataSourceBackup,
      itemStopBackup,
      _getNFS,
      _getSFTP,
      _getSMB,
      _getUSB,
      _getDevices,
      _getGroups,
    } = this.state;

    const formLayout = {
      colon: false,
      layout: "vertical",
      style: { paddingBottom: 0 },
    };
    const dataSource = [];
    const scheduleType = ["oneshot", "daily", "weekly", "monthly"];
    const backupsType = ["snapshot", "system"];

    const weekOptions = [
      {
        value: 0,
        text: intl.formatMessage({ id: "objects.times.modal.form.sunday" }),
      },
      {
        value: 1,
        text: intl.formatMessage({ id: "objects.times.modal.form.monday" }),
      },
      {
        value: 2,
        text: intl.formatMessage({ id: "objects.times.modal.form.tuesday" }),
      },
      {
        value: 3,
        text: intl.formatMessage({ id: "objects.times.modal.form.wednesday" }),
      },
      {
        value: 4,
        text: intl.formatMessage({ id: "objects.times.modal.form.thursday" }),
      },
      {
        value: 5,
        text: intl.formatMessage({ id: "objects.times.modal.form.friday" }),
      },
      {
        value: 6,
        text: intl.formatMessage({ id: "objects.times.modal.form.saturday" }),
      },
    ];

    // parseia o objeto para JSON
    _getBackups.forEach(function (key, idx) {
      dataSource[idx] = {
        id: key.id,
        data: { ...JSON.parse(key.data) },
      };
    });

    const bulkActions = [
      {
        text: intl.formatMessage({ id: "devices.backups.create" }),
        onClick: () => this.openModal(null),
      },
      {
        text: intl.formatMessage({ id: "devices.backups.delete" }),
        onClick: () => this.showDelete(selectedRows),
        disabled: selectedRows.length == 0,
      },
    ];

    const columns = [
      {
        title: intl.formatMessage({ id: "common.name" }),
        dataIndex: "data.name",
        className: "bbui-ellipsis",
        render: (text) => <span>{text}</span>,
      },
      {
        title: intl.formatMessage({ id: "devices.backups.last.backup" }),
        width: "140px",
        dataIndex: "data.last_backup",
        align: "center",
        render: (item) =>
          item
            ? this.renderLastStatus(item)
            : intl.formatMessage({ id: "devices.backups.backup.not.done" }),
      },
      {
        title: intl.formatMessage({ id: "devices.backups.next.backup" }),
        width: "140px",
        align: "center",
        render: (item) => this.renderNextBackup(item),
      },
      {
        title: intl.formatMessage({ id: "common.progress" }),
        align: "center",
        render: (item) => this.renderProgress(item.data),
      },
      {
        title: intl.formatMessage({ id: "common.status" }),
        align: "center",
        width: "120px",
        align: "center",
        render: (item) => this.renderStatus(item),
        key: "status",
      },
      {
        title: intl.formatMessage({ id: "common.actions" }),
        align: "right",
        width: "90px",
        render: this.renderActions,
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
      },
    };

    let deleteMessage = "";
    if (selectedBackup != null) {
      if (Array.isArray(selectedBackup) && selectedBackup.length > 0) {
        deleteMessage = (
          <div>
            <p>
              {intl.formatMessage(
                { id: "common.confirm" },
                {
                  action: intl
                    .formatMessage({ id: "common.delete" })
                    .toLowerCase(),
                  len: selectedBackup.length,
                  type: intl
                    .formatMessage({ id: "common.backup" })
                    .toLowerCase(),
                  value: "",
                }
              )}
            </p>
            <ul>
              {selectedBackup.map((item, index) => {
                return <li key={index}>{item.data["name"]}</li>;
              })}
            </ul>
          </div>
        );
      }
    }

    return (
      <Denied denied={denied}>
        <Fragment>
          {showMonitorBackup && (
            <BackupsDevices
              dataSource={dataSourceBackup}
              visible={showMonitorBackup}
              onCancel={this.closeModal}
            />
          )}
          <DraggableModal
            width={720}
            onCancel={this.closeModal}
            onOk={this.handleSave}
            visible={showModalBackup}
            okText={intl.formatMessage({ id: "common.save" })}
            cancelText={intl.formatMessage({ id: "common.cancel" })}
            title={
              <Fragment>
                {" "}
                {action}{" "}
                <FormattedMessage
                  id="common.backup"
                  defaultMessage={intl.formatMessage({ id: "common.backup" })}
                />{" "}
              </Fragment>
            }
            lockForm={lockForm}
          >
            <Form id="form-backups" layout="vertical">
              <Card
                size="small"
                title={intl.formatMessage({
                  id: "devices.community.form.general",
                })}
                style={{ marginBottom: 12 }}
              >
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      {...formLayout}
                      label={intl.formatMessage({ id: "common.name" })}
                    >
                      {getFieldDecorator("name", {
                        initialValue: selectedBackup["name"],
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage(
                              { id: "common.error.required" },
                              {
                                name: intl.formatMessage({ id: "common.name" }),
                              }
                            ),
                          },
                        ],
                      })(
                        <Input
                          disabled={lockForm}
                          placeholder={intl.formatMessage({
                            id: "common.name",
                          })}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      {...formLayout}
                      label={intl.formatMessage({
                        id: "devices.backups.backup.devices",
                      })}
                    >
                      {getFieldDecorator("devices", {
                        rules: [
                          {
                            required: Array.isArray(getFieldValue("groups"))
                              ? getFieldValue("groups").length == 0
                                ? true
                                : false
                              : true,
                            message: intl.formatMessage(
                              { id: "common.error.required" },
                              {
                                name: intl.formatMessage({
                                  id: "devices.backups.backup.devices",
                                }),
                              }
                            ),
                          },
                        ],
                      })(
                        <Select
                          id="devices-list"
                          style={{ marginRight: "8px" }}
                          showSearch
                          onChange={(value) =>
                            this.handleChangeSelect("devices", value)
                          }
                          placeholder={intl.formatMessage({
                            id: "devices.backups.backup.select.device",
                          })}
                          optionFilterProp="children"
                          mode="tags"
                        >
                          {
                            <Select.OptGroup label="Devices" key="devices">
                              {_getDevices.map((item, idx) => (
                                <Option
                                  key={idx}
                                  value={item.id.toString()}
                                  type={item.type}
                                  text={item.text}
                                >
                                  {item.text}
                                </Option>
                              ))}
                            </Select.OptGroup>
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      {...formLayout}
                      label={intl.formatMessage({
                        id: "devices.backups.backup.devices.group",
                      })}
                    >
                      {getFieldDecorator("groups", {
                        rules: [
                          {
                            required: Array.isArray(getFieldValue("devices"))
                              ? getFieldValue("devices").length == 0
                                ? true
                                : false
                              : true,
                            message: intl.formatMessage(
                              { id: "common.error.required" },
                              {
                                name: intl.formatMessage({
                                  id: "devices.backups.backup.devices.group",
                                }),
                              }
                            ),
                          },
                        ],
                      })(
                        <Select
                          id="group-list"
                          style={{ marginRight: "8px" }}
                          showSearch
                          onChange={(value) =>
                            this.handleChangeSelect("groups", value)
                          }
                          placeholder={intl.formatMessage({
                            id: "devices.backups.backup.select.device.group",
                          })}
                          optionFilterProp="children"
                          mode="tags"
                        >
                          {
                            <Select.OptGroup label="Groups" key="groups">
                              {_getGroups.map((item, idx) => (
                                <Option
                                  key={idx}
                                  value={item.id.toString()}
                                  type={item.type}
                                  text={item.text}
                                >
                                  {item.text}
                                </Option>
                              ))}
                            </Select.OptGroup>
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      {...formLayout}
                      label={intl.formatMessage({ id: "common.type" })}
                    >
                      {getFieldDecorator("type", {
                        initialValue: selectedBackup["type"] || backupsType[0],
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage(
                              { id: "common.error.required" },
                              {
                                name: intl.formatMessage({ id: "common.type" }),
                              }
                            ),
                          },
                        ],
                      })(
                        <Select disabled={lockForm}>
                          {backupsType.map((item, idx) => (
                            <Option key={idx} value={item}>
                              {intl.formatMessage({
                                id: `devices.backups.backup.${item}`,
                              })}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...formLayout}
                      label={intl.formatMessage({
                        id: "devices.backups.backup.remote.storage",
                      })}
                    >
                      {getFieldDecorator("remote_storage", {
                        initialValue: selectedBackup["remote_storage"],
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage(
                              { id: "common.error.required" },
                              {
                                name: intl.formatMessage({
                                  id: "devices.backups.backup.remote.storage",
                                }),
                              }
                            ),
                          },
                        ],
                      })(
                        <Select
                          id="remote-storage-list"
                          style={{ marginRight: "8px" }}
                          showSearch
                          allowClear
                          onChange={(value) =>
                            this.handleChangeSelect("remote_storage", value)
                          }
                          placeholder={intl.formatMessage({
                            id: "devices.backups.backup.select.type",
                          })}
                          optionFilterProp="children"
                        >
                          {
                            <Select.OptGroup label="NFS" key="NFS">
                              {_getNFS.map((item, idx) => (
                                <Select.Option
                                  key={idx}
                                  value={item.id}
                                  type={item.type}
                                  text={item.text}
                                >
                                  {item.text}
                                </Select.Option>
                              ))}
                            </Select.OptGroup>
                          }
                          {
                            <Select.OptGroup label="STFP" key="STFP">
                              {_getSFTP.map((item, idx) => (
                                <Select.Option
                                  key={idx}
                                  value={item.id}
                                  type={item.type}
                                  text={item.text}
                                >
                                  {item.text}
                                </Select.Option>
                              ))}
                            </Select.OptGroup>
                          }
                          {
                            <Select.OptGroup label="SMB" key="SMB">
                              {_getSMB.map((item, idx) => (
                                <Select.Option
                                  key={idx}
                                  value={item.id}
                                  type={item.type}
                                  text={item.text}
                                >
                                  {item.text}
                                </Select.Option>
                              ))}
                            </Select.OptGroup>
                          }
                          {
                            <Select.OptGroup label="USB" key="USB">
                              {_getUSB.map((item, idx) => (
                                <Select.Option
                                  key={idx}
                                  value={item.id}
                                  type={item.type}
                                  text={item.text}
                                >
                                  {item.text}
                                </Select.Option>
                              ))}
                            </Select.OptGroup>
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      {...formLayout}
                      label={intl.formatMessage({
                        id: "devices.backups.backup.retention.backup",
                      })}
                    >
                      {getFieldDecorator("retention_count", {
                        initialValue: selectedBackup["retention_count"],
                        rules: [
                          {
                            required: !getFieldValue("retention_percent"),
                            message: intl.formatMessage(
                              { id: "common.error.required" },
                              {
                                name: intl.formatMessage({
                                  id: "devices.backups.backup.retention.backup",
                                }),
                              }
                            ),
                          },
                        ],
                      })(
                        <InputNumber
                          min={1}
                          max={2000}
                          disabled={lockForm}
                          placeholder={intl.formatMessage({
                            id: "devices.backups.backup.retention.backup",
                          })}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...formLayout}
                      label={intl.formatMessage({
                        id: "devices.backups.backup.retention.percent",
                      })}
                    >
                      {getFieldDecorator("retention_percent", {
                        initialValue: selectedBackup["retention_percent"],
                        rules: [
                          {
                            required: !getFieldValue("retention_count"),
                            message: intl.formatMessage(
                              { id: "common.error.required" },
                              {
                                name: intl.formatMessage({
                                  id: "devices.backups.backup.retention.percent",
                                }),
                              }
                            ),
                          },
                        ],
                      })(
                        <InputNumber
                          min={1}
                          max={100}
                          disabled={lockForm}
                          placeholder={intl.formatMessage({
                            id: "devices.backups.backup.retention.percent",
                          })}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card
                size="small"
                title={intl.formatMessage({
                  id: "devices.backups.backup.schedule",
                })}
                style={{ marginBottom: 12 }}
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      {...formLayout}
                      label={intl.formatMessage({
                        id: "devices.backups.backup.schedule",
                      })}
                    >
                      {getFieldDecorator("schedule", {
                        initialValue: selectedBackup["schedule"],
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage(
                              { id: "common.error.required" },
                              {
                                name: intl.formatMessage({
                                  id: "devices.backups.backup.schedule",
                                }),
                              }
                            ),
                          },
                        ],
                      })(
                        <Select
                          disabled={lockForm}
                          allowClear
                          placeholder={intl.formatMessage({
                            id: "devices.backups.backup.select.type",
                          })}
                        >
                          {scheduleType.map((item, idx) => (
                            <Option key={idx} value={item}>
                              {intl.formatMessage({
                                id: `devices.backups.schedule.${item}`,
                              })}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  {getFieldValue("schedule") == "oneshot" && (
                    <Col span={12}>
                      <Form.Item
                        {...formLayout}
                        label={intl.formatMessage({
                          id: "devices.backups.schedule.date.time",
                        })}
                      >
                        {getFieldDecorator("value_time", {
                          initialValue:
                            moment(
                              selectedBackup["value_time"],
                              "YYYY-MM-DD HH:ss",
                              true
                            ).isValid() == true
                              ? moment(selectedBackup["value_time"])
                              : moment(new Date()),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage(
                                { id: "common.error.required" },
                                {
                                  name: intl.formatMessage({
                                    id: "devices.backups.schedule.date.time",
                                  }),
                                }
                              ),
                            },
                          ],
                        })(
                          <DatePicker
                            format="DD/MM/YYYY HH:mm"
                            locale={datepicker_locale[lang]}
                            style={{ width: 100 + "%" }}
                            showTime
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {getFieldValue("schedule") == "weekly" && (
                    <Col span={12}>
                      <Form.Item
                        {...formLayout}
                        label={intl.formatMessage({
                          id: "settings.backups.weekday",
                        })}
                      >
                        {getFieldDecorator("value_weekday", {
                          initialValue: selectedBackup["value_weekday"],
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage(
                                { id: "common.error.required" },
                                {
                                  name: intl.formatMessage({
                                    id: "settings.backups.weekday",
                                  }),
                                }
                              ),
                            },
                          ],
                        })(
                          <Select
                            allowClear={true}
                            disabled={lockForm}
                            style={{ width: "100%" }}
                            placeholder={intl.formatMessage({
                              id: "devices.backups.schedule.week.day",
                            })}
                          >
                            {weekOptions.map((i, idx) => {
                              return (
                                <Select.Option value={i.value} key={idx}>
                                  {i.text}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {getFieldValue("schedule") == "monthly" && (
                    <Col span={12}>
                      <Form.Item
                        {...formLayout}
                        label={intl.formatMessage({
                          id: "devices.backups.schedule.month.day",
                        })}
                      >
                        {getFieldDecorator("value_day", {
                          initialValue: selectedBackup["value_day"],
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage(
                                { id: "common.error.required" },
                                {
                                  name: intl.formatMessage({
                                    id: "devices.backups.schedule.month.day",
                                  }),
                                }
                              ),
                            },
                          ],
                        })(
                          <InputNumber
                            min={1}
                            max={31}
                            disabled={lockForm}
                            placeholder={intl.formatMessage({
                              id: "devices.backups.schedule.month.day",
                            })}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(getFieldValue("schedule") == "daily" ||
                    getFieldValue("schedule") == "weekly" ||
                    getFieldValue("schedule") == "monthly") && (
                    <Col span={12}>
                      <Form.Item
                        {...formLayout}
                        label={intl.formatMessage({
                          id: "settings.backups.hour",
                        })}
                      >
                        {getFieldDecorator("value_time", {
                          initialValue: selectedBackup["value_time"]
                            ? moment(selectedBackup["value_time"], "HH:mm")
                            : moment(new Date(), "HH:mm"),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage(
                                { id: "common.error.required" },
                                {
                                  name: intl.formatMessage({
                                    id: "settings.backups.hour",
                                  }),
                                }
                              ),
                            },
                          ],
                        })(
                          <TimePicker
                            disabled={lockForm}
                            format={"HH:mm"}
                            style={{ width: "100%" }}
                            placeholder={intl.formatMessage({
                              id: "settings.backups.select.time",
                            })}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Card>
            </Form>
          </DraggableModal>
          <ConfirmDelete
            group={selectedBackup}
            visible={showDelete}
            onCancel={this.hideDelete}
            title={intl.formatMessage({ id: "devices.backups.delete" })}
            message={deleteMessage}
            onConfirm={this.deleteBackup}
            cancelText={intl.formatMessage({ id: "common.cancel" })}
            okText={intl.formatMessage({ id: "common.delete" })}
          />
          {/*<ConfirmDelete
        visible={showModalStopBackup}
        onCancel={() => this.hideModalStopBackup()}
        title={this.props.intl.formatMessage({ id: "common.stop" }) + " backup"}
        message={this.props.intl.formatMessage({ id: "settings.backups.confirm.stop" })}
        onConfirm={() => { this._stopBackups(itemStopBackup) }}
        cancelText={intl.formatMessage({ id: "common.cancel" })}
        okText={intl.formatMessage({ id: "common.confirm.ok" })}
      />*/}
          <Datagrid
            id="backups"
            bulkActions={bulkActions}
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            filter={true}
            onChange={this.handleChange}
            pagination={pagination}
            rowSelection={rowSelection}
            total={pagination.total}
          />
        </Fragment>
      </Denied>
    );
  }
}
export default injectIntl(Form.create({})(Backups));
