import React, { Component, Fragment } from "react";
import { Button, Col, Icon, Input, Row } from "antd";
import { injectIntl } from "react-intl";

class AddList extends Component {
  constructor(props) {
    super(props);

    const value = props.value || [];
    this.state = { items: value };
    this.textInput = React.createRef();
    this.select = React.createRef();
  }
  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || []),
      };
    }
    return null;
  }
  addValue = () => {
    const { items } = this.state;

    let val = this.textInput.current.state.value
    var object = items.some(p => p == val)

		if (object === false) {
			items.push(val);
    }
    
    this.handleValuesChange(items);
    this.textInput.current.state.value = "";
    this.textInput.current.focus();

  }
  removeValue = () => {
    const { items } = this.state;
    const options = [...this.select.current.selectedOptions];

    options.map((option) => {
      const index = items.indexOf(option.value);
      if (index > -1) {
        items.splice(index, 1);
      }
    });

    this.setState({ items });
  }
  handleValuesChange = items => {
    if (Array.isArray(items)) {
      if (!('value' in this.props)) {
        this.setState({ items });
      }
      this.triggerChange({ items });
    }
  };
  triggerChange = changedValue => {
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(Object.assign({}, this.state, changedValue));
    }
  };
  render() {
    const { items } = this.state;
    const { intl } = this.props;

    return <Fragment>
      <div style={{ display: 'flex', marginBottom: 8 }}>
        <Input style={{ marginRight: '8px' }} ref={this.textInput} />
        <Button onClick={this.addValue} className="btn btn-sm" title={intl.formatMessage({ id: "common.add.to.list" })} style={{backgroundColor: "buttonface"}}><i className="fa fa-plus" /></Button>
      </div>
      <div style={{ display: 'flex' }}>
        <select style={{ marginRight: '8px' }} onChange={this.handleValuesChange} ref={this.select} multiple="multiple" className="ant-input" id="addlist">
          {items.map((item, i) => <option value={item} key={`${item}_${i}`}>{item}</option>)}
        </select>
        <Button onClick={this.removeValue} className="btn btn-sm" title={intl.formatMessage({ id: "common.del.to.list" })} style={{backgroundColor: "buttonface", height: 32, marginTop: 20}}><i className="fa fa-minus" /></Button>
      </div>
    </Fragment>
  }
}

export default injectIntl(AddList);