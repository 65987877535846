import React, { Component } from "react";
import { Alert } from "antd";

/**
 * Messages to be shown inside a form
 * 
 * @param { object } message The object containing the type of message (success, info, warning, error) and the content
 * @example { type: "Success", content: "Data saved successfully" }
 */ 
class Messages extends Component{
  render(){
    const { message } = this.props;

    if(message == null)
      return <div />;

    return <Alert type={ message.type } message={ message.content } />
  }
}

Messages.defaultProps = {
  message: null
}

export default Messages;