import React, { Component, Fragment } from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import * as ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "/bbui/public/css/font-awesome.min.css";
import "/bbui/public/css/AdminLTE.css";
import "/bbui/public/css/bootstrap.min.css";
import "/bbui/public/css/colors.css";
import "/bbui/public/css/ui.css";
import { IntlProvider, addLocaleData } from "react-intl";
import AppLayout from "/layout/appLayout";
import Login from "/modules/login";
import ResetPassword from "/modules/resetPassword";
import NoMatch from "/layout/noMatch";
import enMessages from "/i18n/en";
import ptBRMessages from "/i18n/pt_BR";
import esMessages from "/i18n/es";
import ptLocaleDate from "react-intl/locale-data/pt";
import esLocaleDate from "react-intl/locale-data/es";
import Axios from "axios";
import { Spin, Icon, Row, message } from "antd";
import Wizard from "/modules/wizard";
import params from "./public/branding/params.json";
import AxiosFormData from "/helpers/AxiosFormData";
import { ajaxUrl } from "/config/constants";
import { getCookie } from "/bbui/helpers/utils";
import { relativeTimeThreshold } from "moment";

addLocaleData([...ptLocaleDate, ...esLocaleDate]);
document.title = params.title;

const package_version = "gsm";
let language = navigator.language.startsWith("pt") ? "pt-BR" : navigator.language.startsWith("es") ? "es-ES" : "en-US";
let loginlock = false;
const lang = window.localStorage.getItem("language");
if (lang == null) {
  window.localStorage.setItem("language", language);
}
function checkSession() {
  const data = Axios.get("/ajax/login.php?act=checkSessionLogin");
  return data;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedSession: false,
      sso_enabled: false,
      saml: '',
      language: window.localStorage.getItem("language"),
      loggedIn: false,
      install: false,
      disclaimerObj: {
        valid: false,
        enabled: false,
        type: "",
        lang:
          window.localStorage.getItem("language") || window.navigator.language,
        message: {
          "pt-BR": "",
          "en-US": "",
          "es-ES": "",
        },
      },
      languageSystem: "",
    };

    //this.checkSaml = this.checkSaml.bind(this);
  }
  checkIntervalFunc = () =>
    checkSession().then((res) => {
      if (res.data === "") {
        this.setState({ checkedSession: true, loggedIn: false, install: true });
      } else {
        if (res.data == "-2") {
          const { languageSystem } = this.state;

          let msg = "";
          languageSystem == "en_US"
            ? (msg = "Please wait while system update is running!")
            : (msg =
              "Aguarde enquanto a atualização do sistema está em execução!");

          loginlock = true;
          this.setState({ loginlocked: true });
          message.config({ duration: 14, maxCount: 1 });
          message.warning(msg);
        } else {
          if (loginlock == true) {
            window.location.reload(true);
          }
        }
        let { checkedSession, loggedIn, install } = this.state;
        let loggedInTest = res.data == 1;
        if (
          !(
            checkedSession === true &&
            loggedIn === loggedInTest &&
            install === false
          )
        ) {
          this.setState({
            checkedSession: true,
            loggedIn: loggedInTest,
            install: false,
          });
        }
      }
    });

  handleDisclaimer = () => {
    const { disclaimerObj } = this.state;
    let langStandardDisclaimer;
    if (lang != null) langStandardDisclaimer = lang;
    else langStandardDisclaimer = window.localStorage.getItem("language");

    document.cookie = "disclaimer=true;";
    this.setState({
      disclaimerObj: {
        enabled: disclaimerObj.enabled,
        type: "",
        valid: true,
        lang: langStandardDisclaimer,
        message: {
          "pt-BR": "",
          "en-US": "",
          "es-ES": "",
        },
      },
    });
  };

  checkSaml = () => {

    const { languageSystem } = this.state;
    // Check if saml is enable to render link sso
    Axios.post(
      ajaxUrl[package_version].login,
      AxiosFormData({ act: "checkSSO" })
    ).then((res) => {
      if (res.data != null) {
        let sso_enabled = !(res.data['sso_enabled'] == "false");
        let interface_default = res.data['interface'];
        this.setState({ sso_enabled, interface_default })

        if (interface_default == "saml") {
          if (!this.state.loggedIn && !this.state.loginlocked) {

            let paramsURL = window.location.search.substring(1);
            console.log(paramsURL);
            if (paramsURL != "" && paramsURL.split("=")[1] == "saml_error") {

              let msg = "";
              languageSystem == "en_US"
                ? (msg = "Login error with Identity Provider. Please check your credentials.")
                : (msg = "Erro de login com o provedor de identidade. Por favor, verifique suas credenciais.");

              message.error(msg);
              setTimeout(function () { window.location.href = "/saml/login.php?act=login" }, 3000);
            } else {
              return window.location.href = "/saml/login.php?act=login";
            }
          }
        } else {
          this.setState({ loginlocked: false });
          let paramsURL = window.location.search.substring(1);
          if (paramsURL != "" && paramsURL.split("=")[1] == "saml_error") {
            let msg = "";
            languageSystem == "en_US"
              ? (msg = "Login error with Identity Provider. Please check your credentials.")
              : (msg = "Erro de login com o provedor de identidade. Por favor, verifique suas credenciais.");

            message.error(msg);

            setTimeout(function () { window.location.href = "/saml/login.php?act=login" }, 3000);
          }
        }
      }
    });
  };

  checkDisclaimer = () => {
    const { disclaimerObj } = this.state;
    Axios.post(
      ajaxUrl[package_version].login,
      AxiosFormData({ act: "getDisclaimer" })
    ).then((res) => {

      let valid = false;
      let langStandardDisclaimer = window.localStorage.getItem("language");
      let configDisclaimer = {};
      if (res.data != null) {
        configDisclaimer = res.data;
        let message_pt;
        let message_en;
        let message_es;
        try {
          message_pt = JSON.parse(configDisclaimer.message).pt_BR;
          message_en = JSON.parse(configDisclaimer.message).en_US;
          message_es = JSON.parse(configDisclaimer.message).es_ES;
        } catch (err) {
          message_pt = "";
          message_en = "";
          message_es = "";
        }
        // modal cookie confirmed ?
        valid = getCookie("disclaimer") == "true";

        this.setState(
          {
            ...disclaimerObj,
            disclaimerObj: {
              valid,
              enabled: configDisclaimer.enabled == "true",
              type: configDisclaimer.type,
              lang: langStandardDisclaimer,
              message: {
                "es-ES": message_es,
                "pt-BR": message_pt,
                "en-US": message_en,
              },
            },
          }
        );
      }
    });
  };

  componentDidMount() {

    this.checkIntervalFunc();
    this.checkDisclaimer();
    this.checkSaml();
    this.handleLang();
    this.interval = setInterval(this.checkIntervalFunc, 15000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  handleChangeLang = (val) => {
    const { disclaimerObj } = this.state;
    if (val) {
      val = val.replace("_", "-");
      window.localStorage.setItem("language", val);
      disclaimerObj.lang = val;
      this.setState({
        language: val,
        disclaimerObj: {
          ...disclaimerObj,
        },
      });
    }
  };

  handleLang = () => {
    Axios.post(
      ajaxUrl[package_version].login,
      AxiosFormData({ act: "getLanguage" })
    ).then((res) => {
      if (res.data) {
        let language = res.data.replace("_", "-");
        this.setState({ languageSystem: res.data, language });
        window.localStorage.setItem("language", language);
      }
    });
  };

  handleLogout = () => {
    this.setState({ loggedIn: false });
  };
  render() {
    const { checkedSession, install, loggedIn, disclaimerObj, sso_enabled, interface_default, language, saml } = this.state;
    const messages =
      language && language.startsWith("pt")
        ? ptBRMessages
        : language.startsWith("en")
          ? enMessages
          : esMessages;
    const { loginlocked } = this.state;

    if (!checkedSession && !loginlocked) {
      return (
        <div className="void">
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            style={{ height: "100%", textAlign: "center" }}
          >
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />}
            />
          </Row>
        </div>
      );
    }

    if (install)
      return (
        <IntlProvider locale={language} messages={messages}>
          <BrowserRouter>
            <Switch>
              <Redirect from="/" exact to="/wizard" />
              <Redirect from="/login" exact to="/wizard" />
              <Route
                path="/wizard"
                render={() => <Wizard onChangeLang={this.handleChangeLang} />}
              />
            </Switch>
          </BrowserRouter>
        </IntlProvider>
      );

    return (
      <Fragment>
        <IntlProvider locale={language} messages={messages}>
          <BrowserRouter>
            <Switch>
              {loggedIn && <Redirect from="/login" to="/devices" />}
              <Route path="/resetPassword/:token" component={ResetPassword} />
              {!loginlocked && (
                <Route
                  path="/login"
                  render={() => (
                    <Fragment>
                      {interface_default != 'saml' && <Login
                        checkSaml={this.checkSaml}
                        sso_enabled={sso_enabled}
                        interface_default={interface_default}
                        handleDisclaimer={this.handleDisclaimer}
                        checkDisclaimer={this.checkDisclaimer}
                        disclaimerObj={disclaimerObj}
                        checkIntervalFunc={this.checkIntervalFunc}
                        handleChangeLang={this.handleChangeLang}
                        language={language}
                      />}
                    </Fragment>
                  )}
                />
              )}
              {!loggedIn && <Redirect from="/*" exact to="/login" />}
              <Route
                path="/*"
                render={() => (
                  <AppLayout
                    handleDisclaimer={this.handleDisclaimer}
                    disclaimerObj={disclaimerObj}
                    onLogout={this.handleLogout}
                  />
                )}
              />
              {!loginlocked && <Route component={NoMatch} />}
            </Switch>
          </BrowserRouter>
        </IntlProvider>

      </Fragment>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
