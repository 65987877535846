import React, { Component, Fragment } from "react";
import { DraggableModal } from "/bbui/components/";
import { Form, Input, Select, Button, message } from "antd";
import AxiosFormData from "/helpers/AxiosFormData";
import Axios from "axios";
import { injectIntl, FormattedMessage } from "react-intl";
import { ajaxUrl } from "/bbui/config/constants";
import { product } from "/config/constants";
const package_version = 'app';

class SchedulesGroupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lockForm: false,
      message: null,
      name: '',
      dateGroup: props.dateGroup,
      _getSelect2Schedule: [],
      titlePrefix: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      const { id,intl } = this.props;
      if (id) {
        this.setState({ lockForm: true })
        Axios.post(ajaxUrl[package_version].objects, AxiosFormData({ act: "getObjectGroup", type: "date", id })).then((res) => {
          if (res.data) {
            let dateGroup = res.data[0];

            this.setState({
              dateGroup: {
                lockForm: false,
								id: dateGroup.id,
								name: dateGroup.name,
								description: dateGroup.desc,
								list: JSON.parse(dateGroup.id_objs),
                type: dateGroup.type,
                gsmid: dateGroup.gsmid
              }
            }, () => {
              this.setState({ lockForm: false })
            })
          }
        })
      }
      this.state.titlePrefix = intl.formatMessage({id: 'common.edit'});
      this._getSelect2Schedule();
    }

    if (this.props.dateGroup !== prevProps.dateGroup) {
      this.setState({
        lockForm: false,
        dateGroup: this.props.dateGroup,
      })
    }
  }

  _getSelect2Schedule = () => {
		const formData = new FormData();
		formData.set("act", "_getSelect2Schedule");
		Axios.post(ajaxUrl[package_version].objects_templates, formData).then((res) => {
			this.setState({ _getSelect2Schedule: res.data.items });
		});
  }
  
  handleSave = () => {
    const { intl } = this.props;
    const { dateGroup } = this.state;
    this.setState({ loading: true, lockForm: true });

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { group, form } = this.props;
        const bodyData = new FormData();
        bodyData.set("act", "saveObjectsGroups");
        bodyData.set("table", 'date');
        bodyData.set("obj_date_group-type", 'group');
        bodyData.set("obj_date_group-name", values["obj_date_group-name"]);
        bodyData.set("obj_date_group-desc", values["obj_date_group-desc"]);
        bodyData.set("obj_date_group-list", values["obj_date_group-list"]);

        if (group && "id" in group) {
          bodyData.set("obj_date_group-id", group.id);
        } else if (dateGroup && dateGroup.id) {
          bodyData.set(`obj_date_group-id`, dateGroup.id);
        }

        Axios.post(ajaxUrl[package_version].objects, bodyData).then((res) => {

          this.setState({ lockForm: false, loading: false });

          if (product == "GSM" && Array.isArray(res.data) && res.data.length == 2)
            if (res.data[1]['errorcode'] == '001') {
              this.props.form.resetFields();
              this.handleCancel();
              return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
            }
          
            if (res.data === "Seu login não permite acesso a esta area." || res.data === "Your login does not allow access to this area.") {
              this.props.form.resetFields();
              this.handleCancel();
              return message.error(this.props.intl.formatMessage({ id: "common.dont.permission.edit" }));
            } else if (res.data === "High Availabilty Habilitado - Estado: Backup - Acesso Bloqueado." || res.data === "High Availabilty Enabled - State: Backup - Blocked Access.") {
              this.props.form.resetFields();
              this.handleCancel();
              return message.error(this.props.intl.formatMessage({ id: "common.blocked.ha" }));
            }
      
          if (res.data[0].success == 1) {
            message.success(intl.formatMessage({ id: "common.save.success" }));
            this.props.form.resetFields();
            this.handleCancel();
            this.props.onSuccess();
          } else {
            res.data.forEach(erro => {
              form.setFields({
                [erro.field]: {
                  value: values[erro.field],
                  errors: [new Error(intl.formatMessage({ id: "common.invalid.value" }))],
                },
              });

              if (res.data[0]['exists']) {
                form.setFields({
                  'obj_date_group-name': {
                    value: form.getFieldValue('obj_date_group-name'),
                    errors: [new Error(this.props.intl.formatMessage({ id: "common.alreadyExists" }))]
                  }
                })
              }
            })
            message.error(intl.formatMessage({ id: "objects.actions.create.error" }));
          }
        });
      } else {
        this.setState({ lockForm: false });
      }
    });
  }


  handleCancel = () => {
    const { onCancel } = this.props;
    this.props.form.resetFields();
    onCancel();
  }

  renderSchedules() {
    if(this.props._getSelect2Schedule){
      const { _getSelect2Schedule } = this.props;
      if (_getSelect2Schedule) {
        return _getSelect2Schedule.map((g) => {
          return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
        });
      }
    } else if (this.state._getSelect2Schedule) {
        const { _getSelect2Schedule } = this.state;
        if (_getSelect2Schedule) {
          return _getSelect2Schedule.map((g) => {
            return <Select.Option key={g.id} value={g.id}>{g.text}</Select.Option>;
          });
        }
    }
  }

  render() {
    let { intl, visible, group, titlePrefix } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { lockForm, dateGroup } = this.state;
    const { TextArea } = Input;
    const formLayout = { colon: false, style: { paddingBottom: 0 } };

    let lockFormGsmUtm = lockForm;
    let controleLoadingButton = false;

    titlePrefix = titlePrefix ? titlePrefix : this.state.titlePrefix;

    let rows = [];
    if (group) {

      //Object GSM disabled
      lockFormGsmUtm = group.gsmid ? true : lockForm;
      controleLoadingButton = group.gsmid ? false : lockForm;

      if (group.id !== undefined) {
        rows = JSON.parse(group.dates);
      }
    } else {
      group = [];
    }

    if (dateGroup){
      group = dateGroup;
      rows = group.list.map(i => parseInt(i));
    }

    const footerActions = [
			<Button key="cancel" onClick={this.handleCancel}>
				<FormattedMessage id="common.cancel" />
			</Button>,
			<Button loading={controleLoadingButton} disabled={lockFormGsmUtm} key="save" type="primary" onClick={this.handleSave}>
				<FormattedMessage id="common.save" />
			</Button>
		]

    return <DraggableModal
      onOk={this.handleSave}
      onCancel={this.handleCancel}
      confirmLoading={lockFormGsmUtm}
      visible={visible}
      title={<Fragment>{titlePrefix}<FormattedMessage id="objects.modal.group.title" values={{ object: "Schedules" }} /></Fragment>}
      okText={intl.formatMessage({ id: "common.save" })}
      cancelText={intl.formatMessage({ id: "common.cancel" })}
      footer={footerActions}>
      <Form id="form-schedules-group" layout="vertical">
        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.address.modal.form.name" })} >
          {getFieldDecorator('obj_date_group-name', {
            initialValue: group.name,
            rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.address.modal.form.name" }) }) }]
          })(
            <Input disabled={lockFormGsmUtm} />
          )}
        </Form.Item>

        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.address.modal.form.description" })} >
          {getFieldDecorator('obj_date_group-desc', {
            initialValue: group.description,
          })(
            <TextArea disabled={lockFormGsmUtm} />
          )}
        </Form.Item>

        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.addressGroup.modal.object" })}>
          {getFieldDecorator('obj_date_group-list', {
            initialValue: rows,
            rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.addressGroup.modal.object" }) }) }]
          })(
            <Select
              disabled={lockFormGsmUtm}
              style={{ width: "100%" }}
              showSearch
              placeholder="Select"
              optionFilterProp="children"
              mode="multiple"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {this.renderSchedules()}
            </Select>
          )}
        </Form.Item>
      </Form >
    </DraggableModal>
  }
}

SchedulesGroupModal.defaultProps = {
  onCancel: null,
  visible: false
}

export default injectIntl(Form.create({})(SchedulesGroupModal));