import React, { Component, Fragment } from "react";
import { Card, Progress, Tooltip } from "antd";

var palette = [];
palette['0'] = '#f5222d';
palette['1'] = '#faad14';
palette['2'] = '#1890ff';
palette['3'] = '#52c41a';
palette['4'] = '#2db7f5';

class CardProgress extends Component {

  renderFields(fields) {
    let cssStyle = { marginBottom: 8 };
    const { activeZone } = this.props;

    return fields.map((field, idx) => {
      return <div style={cssStyle} key={idx}>
        <span onClick={ () => this.props.onclick(field)} style={{ margin: 0, cursor: 'pointer', fontWeight: activeZone == field.name ? 'bold' : 'normal' }}>{field.name}</span>
        <Tooltip placement="right" title={field.hits}>
          <Progress strokeColor={this.props.color ? this.props.color : palette[idx]} percent={field.percent} size="small" />
        </Tooltip>
      </div>
    });
  }

  render() {
    return <Fragment>
      <Card size="small" loading={this.props.loading} headStyle={{minHeight: 30, background: '#999', color: 'white'}} title={this.props.title} style={{ marginBottom: 12,  height: this.props.height + 'px' }}>
        {this.renderFields(this.props.fields)}
      </Card>
    </Fragment>
  }
}

CardProgress.defaultProps = {
  title: null,
}

export default CardProgress;