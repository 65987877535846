const SafeJSONParse = ({text, type, defaultObj=null}) => {
  if (typeof(text)!='string') return defaultObj?defaultObj:text
  try {
    let json = JSON.parse(text);
    if ( (typeof(json)==type) || (type=='array'&&Array.isArray(json)) ) {
      return json
    } else {
      return defaultObj
    }
  } catch (e) {
    console.log(e);
    return defaultObj;
  }
}

export default SafeJSONParse;