import React, { Component } from "react";
import { Button, Card, Form, Icon, Input } from "antd";

class RetrieveForm extends Component {
  handleSubmit = () => {
    const { getFieldsValue } = this.props.form;
    this.props.handleRetrieve(getFieldsValue());
    this.props.onToggle();
  }
  render() {
    const { onToggle, emailRequired } = this.props;
    const { getFieldDecorator } = this.props.form;
    return <Form>
      <Card className="login-card-content">
        <Form.Item>
          {getFieldDecorator('login', {
            rules: [
              { required: true, message: emailRequired }
            ]
          })(
            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="E-mail address" />
          )}
        </Form.Item>
        <Button size="large" onClick={this.handleSubmit} block type="primary" htmlType="submit">Retrieve Password</Button>
      </Card>
      <p><Button onClick={onToggle} size="large" ghost type="primary" block>Login</Button></p>
    </Form>;
  }
}

export default Form.create({ name: 'retrieveForm' })(RetrieveForm);