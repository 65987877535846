import React, { Component } from "react";
import { FormModal } from "/bbui/components/";
import Axios from "axios";
import AxiosFormData from "/helpers/AxiosFormData";
import { message } from "antd";

class ProfileModal extends Component {
  constructor(props) {
    super(props);

    this.userDetailsFields = [
      { type: "text", name: "name", label: "Name", disabled: false, rules: [{ required: true }] },
      { type: "text", name: "email", label: "E-mail", disabled: false, rules: [{ required: true }] }
    ];

    this.passwordFields = [
      { type: "password", name: "pass", label: "Password", rules: [{ required: true }] },
      { type: "password", name: "repass", label: "Confirm Password", rules: [{ required: true }] },
    ]

    this.changePassBtnShow = [{ name: "btnShowPass", type: "button", onClick: this.showPasswordFields, content: "Change Password", primary: true }];
    this.changePassBtnHide = [{ name: "btnHidePass", type: "button", onClick: this.showPasswordFields, content: "Cancel" }];

    this.state = {
      dataSource: null,
      fields: this.userDetailsFields.concat(this.changePassBtnShow),
      loading: true,
      showPasswd: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if ("visible" in nextProps && nextProps.visible == true) {
      Axios.post("/ajax/settings-admin.php", new AxiosFormData({ act: "editAdminProfile" })).then((res) => {

        this.setState({ dataSource: res.data[2], type: res.data[2]['type'], loading: false });
      });
    }
  }

  handleSave = (values) => {
    this.setState({ loading: true });
    
    if (this.state.type === 'remote') {
      this.handleClose();
      return message.error(this.props.intl.formatMessage({ id: "common.save.error" }));
    }

    let formData = {
      id: null,
      "adm_users-name": values.name,
      "adm_users-email": values.email,
      "adm_users-pass": "",
      "adm_users-repass": "",
      act: "saveAdminProfile"
    };

    if (this.state.showPasswd) {
      formData["adm_users-pass"] = values.pass;
      formData["adm_users-repass"] = values.repass;
    }
    const data = new AxiosFormData(formData);
    Axios.post("/ajax/settings-admin.php", data).then((res) => {
      if ("msg" in res.data && res.data.msg == "1") {
        this.props.onLogout();
      }
    })
  }

  showPasswordFields = () => {
    const { showPasswd } = this.state;
    if (!showPasswd) {
      this.setState({
        fields: this.userDetailsFields.concat(this.passwordFields, this.changePassBtnHide),
        showPasswd: true
      });
    } else {
      this.setState({
        fields: this.userDetailsFields.concat(this.changePassBtnShow),
        showPasswd: false
      });
    }
  }

  handleClose = () => {
    const { onClose } = this.props;
    this.setState({ dataSource: null });
    onClose();
  }

  render() {
    const { dataSource, fields, type, loading } = this.state;

    let data = fields;
    if (type === 'remote') {
      data[0].disabled = true;
      data[1].disabled = true;
      data[2].type = "hidden";
    }

    return <FormModal
      dataSource={dataSource}
      visible={this.props.visible}
      titlePrefix=""
      onSave={this.handleSave}
      onCancel={this.handleClose}
      loading={loading}
      formConfig={{ fields: data }}
      title="Edit Profile"
    />
  }
}

export default ProfileModal;