/**
 * Field for multiple selections that opens a modal with a datagrid that's filterable
 * @version 1.0
 *
 * @param {String} key The key of each row (usually the id of the item)
 * @param {Mixed[]} datagrid All the items that should be shown in the datagrid inside the modal, only one column is supported
 * @param {int[]} initialValue The initial value of the field
 * @param {function} onChange The callback when the modal is hit save
 * @param {String} title Modal title
 * 
 * VERY IMPORTANT DISCLAIMER: WHEN USING THE FIELD WITH ANT DESIGN FORMS USE THE TYPE
 * ATTRIBUTE SET TO "ARRAY" INSIDE RULES, OTHERWISE THE "REQUIRED" VALIDATION WILL NOT WORK
 * 
 * Example:
 * getFieldDecorator("MyField", { rules[ { type: "array", required: true }]})(<FieldModal />)
 * 
 */
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Icon, Input, Select } from "antd";
import { BulkActionsMenu, DatagridModal } from ".";
import Axios from "axios";

class FieldModal extends Component{
  constructor(props){
    super(props);
    this.state = {
      dataSourceFiltered: props.dataSource,
      fieldPlaceholder: "",
      showModal: false,
      loading: false,
      searchSelect: "all",
      searchValue: "",
      selectedRows: props.value,
      selectedRowKeys: props.value
    };
  }
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || {}),
      };
    }
    return null;
  }
  shouldComponentUpdate(nextProps, nextState){
    /* When modal closes */
    if(!nextState.showModal && this.state.showModal){
      this.handleResetModal();
    }

    /* When modal opens */
    if(nextState.showModal && !this.state.showModal){
      if(nextProps.value != undefined){
        this.setState({ selectedRows: nextProps.value, selectedRowKeys: nextProps.value });
      }


      if(this.props.dataSourceAxios != null){
        const dta = this.props.dataSourceAxios;
        const { dtKey } = this.props;
        this.setState({ loading: true });
        Axios.post(dta.url, { act: dta.act }).then((res) => {
          const dataSource = res.data.map((item, index) => {
            const obj = {};
            obj[dtKey] = item[dtKey];
            obj.name = item.name;
            obj.key = item[dtKey];
            return obj;
          })
          this.setState({ dataSourceFiltered: dataSource, loading: false });
        });
      }
    }

    
    return true;
  }
  handleResetModal = () => {
    this.setState({
      dataSourceFiltered: [],
      searchSelect: "all",
      searchValue: "",
      selectedRows: this.props.value,
      selectedRowKeys: this.props.value
    });
  }
  saveModal = () => {
    const { selectedRows } = this.state;

    let msg = "";
    if(selectedRows.length > 0){
      msg = selectedRows.length + " items selected";
    }
    
    this.setState({ fieldPlaceholder: msg });
    this.triggerChange(selectedRows);
    this.closeModal();
  }
  onSelect = (row, selected, selectedRows) => {
    const { dtKey } = this.props;
    const sels = selectedRows.map((item) => item[dtKey]);
    const selsKeys = selectedRows.map((item) => item.key);
    this.setState({
      selectedRows: sels,
      selectedRowKeys: selsKeys
    })
  }
  onSelectAll = (selected) => {
    const { dataSource, dtKey } = this.props;
    
    if(!selected){
      this.setState({ selectedRows: [], selectedRowKeys: [] });
    }else{
      const selectedRows = dataSource.map((item) => item[dtKey]);
      const selectedRowKeys = dataSource.map((item, index) => index);
      this.setState({ selectedRows, selectedRowKeys })
    }
  };
  onDeselectAll = () => this.setState({ selectedRows: [], selectedRowKeys: [] });
  onInvertSelection = () => {
    const { dataSource, dtKey } = this.props;
    let { selectedRows } = this.state;

    if(selectedRows.length == 0){
      selectedRows = dataSource.map((item) => item[dtKey]);
      this.setState({ selectedRows: selectedRows, selectedRowKeys: selectedRows });
      return;
    }

    if(selectedRows.length == dataSource.length){
      this.setState({ selectedRows: [], selectedRowKeys: [] });
      return;
    }

    const dts = [ ...dataSource ];
    dataSource.map((row, index) => {
      selectedRows.map((sRow, sIndex) => {
        if(row[dtKey] == sRow){
          delete dts[index];
          selectedRows.splice(sIndex, 1);
        }
      })
    })

    selectedRows = dts.filter((el) => el != null).map((item) => item[dtKey]);
    this.setState({ selectedRows: selectedRows, selectedRowKeys: selectedRows });
  }
  showModal = () => {
    const { disabled } = this.props;
    if(!disabled)
      this.setState({ showModal: true });
  }
  closeModal = () => this.setState({ showModal: false });
  onSearch = () => {
    const { searchSelect, searchValue, selectedRows } = this.state;
    const { dataSource, dtKey } = this.props;

    if(searchSelect == "all" && searchValue == "")
      this.setState({ dataSourceFiltered: dataSource });

      
    if(searchSelect != "all"){
      if(searchSelect == "selected"){
        const result = [];
        dataSource.map((row, index) => {
          selectedRows.map((sRow, sIndex) => {
            if(row[dtKey] == sRow){
              result.push(row);
            }
          })
        })
        this.setState({ dataSourceFiltered: result });
      }else{
        const result = [ ...dataSource ];
        result.map((row, index) => {
          selectedRows.map((sRow, sIndex) => {
            if(row[dtKey] == sRow){
              delete result[index];
            }
          })
        })

        this.setState({ dataSourceFiltered: result.filter((el) => el != null) });
      }
    }
  }
  onSearchSelect = (value) => {
    this.setState({ searchSelect: value });
  }
  focus = () => {
    // Do nothing...
    return;
  }
  triggerChange = changedValue => {
    const { onChange } = this.props;

    if(onChange){
      onChange(changedValue)
    }
  }
  render(){
    const { Search } = Input;
    const { Option } = Select;
    const { disabled, dtKey, name, title } = this.props;
    const { dataSourceFiltered, loading, selectedRowKeys, showModal } = this.state;

    const bulkActions = [
      { text: "Select All", onClick: this.onSelectAll },
      { text: "Deselect All", onClick: this.onDeselectAll },
      { text: "Invert Selection", onClick: this.onInvertSelection },
    ]

    const rowSelection = {
      selectedRowKeys,
      onSelect: this.onSelect,
      onSelectAll: this.onSelectAll,
    }

    const selectBefore = (
      <Select onChange={this.onSearchSelect} defaultValue="all" style={{ width: 110 }}>
        <Option value="all">All</Option>
        <Option value="selected">Selected</Option>
        <Option value="not_selected">Not selected</Option>
      </Select>
    );

    let fieldPlaceholder = "";
    if(this.props.value != null && this.props.value.length > 0){
      fieldPlaceholder = this.props.value.length + " items selected";
    }else if(this.props.value != null && this.props.value.length > 0){
      fieldPlaceholder = this.props.value.length + " items selected";
    }

    return <div className="bbui-fieldModal">
      <Input
        addonAfter={<Icon type="bars" onClick={ this.showModal } />}
        disabled={ disabled }
        name={ name }
        onClick={ this.showModal }
        placeholder={ fieldPlaceholder }
        readOnly={ true } />
      <DatagridModal 
        dataSource={dataSourceFiltered}
        title={title}
        prefix=""
        loading={ loading }
        datagridOptions={{
          pagination: null,
          rowSelection,
          rowKey: dtKey,
          controls: <React.Fragment>
            <Search enterButton addonBefore={selectBefore} onChange={ ({ target }) => this.setState({ searchValue: target.value }) } onSearch={this.onSearch} allowClear />
            <BulkActionsMenu actions = { bulkActions } />
          </React.Fragment>,
          onChange: this.handleTableChange,
          columns: [
            { title: "Item", dataIndex: 'name' }
          ]
        }}
        onSave={this.saveModal}
        onCancel={()=>this.closeModal()}
        visible={showModal} 
      />
    </div>
  }
}

FieldModal.propTypes = {
  dtKey: PropTypes.string,
  dataSource: PropTypes.array,
  dataSourceAxios: PropTypes.object,
  initialValue: PropTypes.array,
  rowSelection: PropTypes.object,
  title: PropTypes.string
}

FieldModal.defaultProps = {
  disabled: false,
  dtKey: "id",
  dataSource: [],
  dataSourceAxios: null,
  initialValue: [],
  rowSelection: {},
  title: "element"
}

export default FieldModal;