import React, { Component, Fragment } from "react";
import { Form, Input, Select, Row, Col, Button } from "antd";
import { injectIntl } from "react-intl";
import Datepicker from "./Datepicker";
import { product } from "../../config/constants";

var _ = require('lodash');
class HeaderFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  renderTitle() {
    const { title } = this.props;
    return <Col span={8}><h3 style={{ marginBottom: 12, marginTop: 0 }}>{title}</h3></Col>
  }

  renderOptions(options) {
    if (Array.isArray(options)) {
      return options.map((opt) => {
        const { id, text } = opt;
        return <Select.Option value={id} key={id}>{text}</Select.Option>
      });
    }
  }

  renderFields(fields) {
    const { getFieldDecorator } = this.props.form;
    let cssStyle = { float: "right", marginBottom: 0, marginTop: 0 };

    
    return fields.map((field, idx) => {
      return <Fragment key={field.name}>
        <Col key={idx} style={cssStyle} span={field.span || 24}>
          <Form.Item key={idx}>
            {field.type == "button" && getFieldDecorator(field.name)(<Button onClick={field.onClick} type={field.typeButton} shape="circle" icon={field.icon}></Button>)}
            {field.type == "text" && getFieldDecorator(field.name, { initialValue: field.initialValue, rules: field.rules })(<Input placeholder={field.placeholder} onChange={field.onChange} />)}
            {field.type == "datePicker" && getFieldDecorator(field.name, { initialValue: field.initialValue, rules: field.rules })(<Datepicker dateChange={field.onChange} initDateRange={field.initDateRange} {...field.showTime} allowed_date_type={product == "UTM" && !!field.allowed_date_type ? field.allowed_date_type : false } selectValue={field.selectValue} format={field.format} active={field.active} maxDays={60} />)}
            {field.type == "select" && getFieldDecorator(field.name, { initialValue: field.initialValue, rules: field.rules })(<Select showSearch={this.props.showSearch} allowClear={field.allowClear} onChange={field.onChange} onSearch={_.debounce(this.props.onSearch, 300)} placeholder={field.placeholder}>{this.renderOptions(field.options)}</Select>)}
            {field.type == "selectOptGroup" && getFieldDecorator(field.name, { initialValue: field.initialValue, rules: field.rules })(
              <Select
                id="devices-list"
                style={{ marginRight: '8px' }}
                showSearch
                optionFilterProp="children"
                onChange={field.onChange}
                placeholder={field.placeholder}>
                {<Select.OptGroup label="Loggers" key="loggers">
                  {field.options.filter(j => j.type == "logger").map(i => <Select.Option key={i.id} value={i.id} type={i.type} text={i.text}>{i.text}</Select.Option>)}
                </Select.OptGroup>}

                {field.groups.map(k => {
                  return <Select.OptGroup label={this.props.intl.formatMessage({ id: "common.group" }) + ' ' + k.text} key={k.text}>
                    <Select.Option key={k.id.split("^")[1]} value={k.id}>{this.props.intl.formatMessage({ id: "common.allDevices" })}</Select.Option>
                    {field.options.filter(j => j.type == "device" && j.group == k.id.split("^")[1]).map(i => <Select.Option key={i.id} value={i.id} type={i.type} text={i.text}>{i.text}</Select.Option>)}
                  </Select.OptGroup>
                }
                )}
                {<Select.OptGroup label="Devices" key="devices">
                  {field.options.filter(j => j.type == "device" && (j.group == "" || j.group == 0 || j.group == null) ).map(i => <Select.Option key={i.id} value={i.id} type={i.type} text={i.text}>{i.text}</Select.Option>)}
                </Select.OptGroup>}
              </Select>)}
          </Form.Item>
        </Col>
      </Fragment>
    });
  }

  renderFilterForm() {
    const { filter } = this.props;

    return <Fragment>
      <Form id="form-header">
        {this.renderFields(filter.fields)}
      </Form>
    </Fragment >
  }

  render() {
    const { filter, title } = this.props;

    return <Fragment>
      <Row gutter={16} className="content-header" style={{ padding: '0px' }}>
        {title && this.renderTitle()}
        {filter && this.renderFilterForm()}
      </Row>
    </Fragment>;
  }
}

HeaderFilter.defaultProps = {
  onSearch: () => {},
  showSearch:false,
  title: null,
  filter: null,
  format: "DD/MM/YYYY",
  showTime: false
};

export default Form.create({})(injectIntl(HeaderFilter));
