import React from "react";
import { Alert } from "antd";
import { injectIntl } from "react-intl";

class Denied extends React.Component {

  render() {
    return this.props.denied === true ? <Alert
      message={this.props.intl.formatMessage({ id: "common.error" })}
      description={this.props.intl.formatMessage({ id: "common.dont.permission.page" })}
      type="error"
      showIcon
    /> : this.props.children
  }
}

Denied.defaultProps = {
  denied: false,
};

export default injectIntl(Denied);