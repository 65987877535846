import React, { Component } from "react";
import { Button, Form, Icon } from "antd";
import { injectIntl } from "react-intl";
import { Datagrid, DraggableModal } from "/bbui/components/";
import { product } from "/config/constants";

class MapObjectModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dataSource: [],
			loading: false,
			deleteModal: null,
			message: null
		};
	}

	handleCancel = () => {
		const { onCancel } = this.props;
		onCancel();
	}

	renderName = (item) => {
		const { intl } = this.props;
		const map_title = [];
		map_title['policy_packages_rules'] = intl.formatMessage({ id: "policy.manager.title" });
		map_title['policy_templates_rules'] = intl.formatMessage({ id: "policies.tab.template.title" });
		map_title['device_templates'] = intl.formatMessage({ id: "devices.template.title" });
		map_title['box_routes'] = intl.formatMessage({ id: "common.routes" }); 
		map_title['box_routes_v6'] = intl.formatMessage({ id: "common.routes" }); 
		map_title['box_net_device'] = intl.formatMessage({ id: "common.interfaces" });
		map_title['auth_radius_servers'] = intl.formatMessage({ id: "common.authentication" }); 
		map_title['firewall_redirects'] = intl.formatMessage({ id: "devices.template.tabs.form.firewall" });
		map_title['firewall_input'] = intl.formatMessage({ id: "devices.template.tabs.form.firewall" });
		map_title['proxy_cache'] = intl.formatMessage({ id: "devices.template.tabs.form.webcache" });
		map_title['profile_ssl'] = intl.formatMessage({ id: "devices.template.tabs.form.proxy" }); 
		map_title['proxy_settings'] = intl.formatMessage({ id: "devices.template.tabs.form.proxy"});
		map_title['profile_webfilter'] = intl.formatMessage({ id: "devices.template.tabs.form.webfilter" }); 
		map_title['profile_atp'] = intl.formatMessage({ id: "profiles.title.atp" }); 
		map_title['multilink_output'] = intl.formatMessage({ id: "devices.template.tabs.form.sdwan" }); 
		map_title['vpnssl_server_ipv4'] = "VPN SSL";
		map_title['vpnssl_server_ipv6'] = "VPN SSL";
		map_title['obj_addr_group'] = intl.formatMessage({ id: "objects.tabs.address" }); 
		map_title['obj_mac_group'] = intl.formatMessage({ id: "policy.form.connection.source.mac.address" }); 
		map_title['obj_services_group'] = intl.formatMessage({ id: "objects.tabs.service" }); 
		map_title['obj_time_group'] = intl.formatMessage({ id: "objects.tabs.time" }); 
		map_title['obj_date_group'] = intl.formatMessage({ id: "objects.tabs.schedule" }); 
		map_title['obj_word_group'] = intl.formatMessage({ id: "objects.tabs.dicionarie" }); 
		map_title['obj_mime_group'] = intl.formatMessage({ id: "objects.tabs.content" });
		map_title['auth_policy'] = intl.formatMessage({id: "common.authentication"});
		map_title['storages'] = intl.formatMessage({id: "common.storage"});
		map_title['dhcp_relay'] = intl.formatMessage({ id: "devices.template.dhcp.modal.titleRelay" });

		const map_interface = [];
		if (product == 'UTM') {
			map_interface['policy_packages_rules'] = '../apps/policy-ipv4.php';
			map_interface['policy_templates_rules'] = '../policies/templates';
			map_interface['box_routes'] = '../apps/network.php#box-router';
			map_interface['box_routes_v6'] = '../apps/network.php#box-router';
			map_interface['box_net_device'] = '../apps/network.php#conf-zone-network';
			map_interface['auth_radius_servers'] = '../apps/auth.php#tab2';
			map_interface['firewall_redirects'] = '../apps/firewall.php';
			map_interface['firewall_input'] = '../apps/firewall.php';
			map_interface['proxy_cache'] = '/apps/webcache.php';
			map_interface['profile_ssl'] = '../apps/proxy.php';
			map_interface['proxy_settings'] = '../apps/proxy.php';
			map_interface['profile_webfilter'] = '../apps/webfilter.php';
			map_interface['profile_atp'] = '../apps/atp.php';
			map_interface['multilink_output'] = '../apps/sdwan.php';
			map_interface['vpnssl_server_ipv4'] = '../apps/vpnssl.php';
			map_interface['vpnssl_server_ipv6'] = '../apps/vpnssl.php';
			map_interface['obj_addr_group'] = '../apps/objects.php';
			map_interface['obj_mac_group'] = '../apps/objects.php';
			map_interface['obj_services_group'] = '../apps/objects.php';
			map_interface['obj_time_group'] = '../apps/objects.php';
			map_interface['obj_date_group'] = '../apps/objects.php';
			map_interface['obj_word_group'] = '../apps/objects.php';
			map_interface['obj_mime_group'] = '../apps/objects.php';
			map_interface['auth_policy'] = '../apps/auth.php';
			map_interface['dhcp_relay'] = '../apps/dhcp.php';

		} else {
			map_interface['policy_packages_rules'] = '../policies/packages/';
			map_interface['policy_templates_rules'] = '../policies/templates/';
			map_interface['device_templates'] = '../devices/templates/';
			map_interface['obj_addr_group'] = '/objects/address';
			map_interface['obj_mac_group'] = '/objects/address';
			map_interface['obj_services_group'] = '/objects/service';
			map_interface['obj_time_group'] = '/objects/time';
			map_interface['obj_date_group'] = '/objects/schedule';
			map_interface['obj_word_group'] = '/objects/dictionaries';
			map_interface['obj_mime_group'] = '/objects/content';
			map_interface['storages'] = '/system/storage';
		}

		return <a href={map_interface[item.interface]} target="_blank">{map_title[item.interface]} <Icon style={{ paddingLeft: 15, paddingRight: 15 }} type="arrow-right" /> {item.name}</a>
	}

	render() {
		const { loading } = this.state;
		const { intl, visible } = this.props;
		let { dataSource } = this.props;

		const columns = [
			{ title: intl.formatMessage({ id: "objects.mapping.modal.usedin" }), key: 'data', render: this.renderName },
		];

		if (Array.isArray(dataSource)) {
			dataSource = dataSource.map((m, id) => ({ ...m, key: m.id || 0, id }))
		} else {
			dataSource = []
		}

		return <DraggableModal
			onCancel={this.handleCancel}
			visible={visible}
			title={intl.formatMessage({ id: "objects.mapping.modal.title" })}
			cancelText={intl.formatMessage({ id: "common.cancel" })}
			footer={[
				<Button key="back" onClick={this.handleCancel}>{intl.formatMessage({ id: "common.close" })}</Button>
			]}>

			<Datagrid
				id='map-object-modal'
				showHeader={false}
				columns={columns}
				dataSource={dataSource}
				loading={loading}
				pagination={false} />
		</DraggableModal>
	}
}

export default injectIntl(MapObjectModal);