import React, { Component } from "react";

/**
 * Modal component based on bootstrap markup
 * 
 * @param {object} children The modal content
 * @param {object} header The modal header
 * @param {object} footer The modal foooter
 * @param {function} onClose Callback to close the modal
 */

class Modal extends Component{
  constructor(props){
    super(props);

    this.onClose = this.onClose.bind(this);
  }
  onClose(e){
    e.preventDefault();
    if(e.target == e.currentTarget){
      this.props.onClose();
    }
  }
  renderHeader(){
    const { header } = this.props;
    return <div className="modal-header">
      <button type="button" className="close" onClick={ this.props.onClose }>×</button>
      <h4 className="modal-title">{ header }</h4>
    </div>;
  }
  renderFooter(){
    const { footer } = this.props;
    return <div className="modal-footer">
      { footer }
    </div>;
  }
  render(){
    const { header, content, footer } = this.props;

    return <div className="modal fade in" style={{ display: "block" }} onClick={ this.onClose }>
      <div className="modal-dialog">
        <div className="modal-content">
          { header && this.renderHeader() }    
          <div className="modal-body">
            { this.props.children }
          </div>
          { footer && this.renderFooter() }
        </div>
      </div>
    </div>;
  }
}

Modal.defaultProps = {
  children: null,
  header: null,
  footer: null,
  onClose: null
};

export default Modal;
