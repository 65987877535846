import React, { Component, Fragment } from "react";
import { Button, Form, Icon, Steps } from "antd";
import FormCore from "./FormCore";

/**
 * Standalone form component, will submit the data and pass the callback to the parent modules
 * 
 * @param { object } formConfig The form object used by the AntDesign to validate and gather the values of the form
 * @param { object } layout The fields layout, it's applied to all of them
 * @param { function } onSubmit The callback of the success submission
 */

class FormSteps extends Component{
  constructor(props){
    super(props);
    
    this.state = { current: 0 };
  }
  setErrors = (errors, values) => {
    let fields = {};
    errors.map((error) => {
      fields[error.field] = {
        value: values[error.field],
        errors: error.errors.map((item) => new Error(item))
      };
    });
    this.props.form.setFields(fields);
  }
  nextStep = () => {
    let { current } = this.state;
    const { form, steps } = this.props;

    const fieldsInStep = steps[current].formConfig.fields.map((item) => item.name);

    form.validateFields(fieldsInStep, (err, values) => {
      if(!err){
        if(current < steps.length){
          current++;
          this.setState({ current });
        }
      }
    });
  }
  prevStep = () => {
    let { current } = this.state;

    if(current > 0){
      current--;
      this.setState({ current });
    }
  }
  onSubmit = () => {

    const { form, onSubmit } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values, (errors) => this.setErrors(errors, values));
      }
    });
  }
  renderSteps(){
    const { Step } = Steps;
    const { steps } = this.props;
    return steps.map((step) => {
      return <Step key={ step.title } title={ step.title } />
    })
  }
  renderForms(){
    const { current } = this.state;
    const { form, steps } = this.props;
    return <Form>
      { steps.map((step, index) => {
        const args = { noFormWrap: true, form: form, formConfig: step.formConfig, visible: ( index == current ) };
        return <FormCore key={ step.title } { ...args } />;
      }) }
      </Form>
  }
  render(){
    const { current } = this.state;
    const { nextText, prevText, steps, submitText } = this.props;

    return <Fragment>
      <Steps current={current}>
        { this.renderSteps() }
      </Steps>
      <div className="ui-steps-content">
      { this.renderForms() }
      </div>
      <div className="ui-steps-action">
        { current > 0 && <Button onClick={ this.prevStep }><Icon type="left" /> { prevText }</Button> }
        { current < (steps.length - 1) && <Button style={{ float: "right" }} onClick={ this.nextStep }>{ nextText } <Icon type="right" /></Button> }
        { current == (steps.length - 1) && <Button type="primary" style={{ float: "right" }} onClick={ this.handleSubmit }>{ submitText }</Button> }
      </div>
    </Fragment>
  }
}

FormSteps.defaultProps = {
  prevText: "Previous",
  nextText: "Next",
  submitText: "Submit",
  layout: {},
  steps: [],
  onSubmit: null
}

export default Form.create({})(FormSteps);