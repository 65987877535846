import React, { memo } from "react"
import { VectorMap } from "react-jvectormap";
import numeral from "numeral";
import "./worldmap.css";

const WorldMap = memo(function WorldMap({ dataSource }) {
  let elements = document.querySelectorAll('.jvectormap-tip');
  if (elements.length > 1) {
    for (let i = 1; i < elements.length; i++) {
      elements[i].remove();
    }
  }

  dataSource = dataSource || {};

  return (
    <VectorMap
      map={"world_mill"}
      backgroundColor="transparent"
      zoomOnScroll={true}
      containerStyle={{
        width: "100%",
        height: "100%"
      }}
      containerClassName="map"
      regionStyle={{
        initial: {
          fill: "#e4e4e4",
          "fill-opacity": 0.9,
          stroke: "none",
          "stroke-width": 0,
          "stroke-opacity": 0
        },
        hover: {
          "fill-opacity": 0.8,
          cursor: "pointer"
        },
        selected: {},
        selectedHover: {}
      }}
      regionsSelectable={false}
      series={{
        regions: [
          {
            values: dataSource, //this is your data
            scale: [
              "#b0c4de",
              "#c5eddf",
              "#a5d5d8",
              "#8abccf",
              "#73a2c6",
              "#5d8abd",
              "#4771b2",
              "#2e59a8",
              "#00429d"
            ],
            // scale: ['#FFEBE7', '#FF5638'],
            normalizeFunction: "polynomial"
          }
        ]
      }}
      onRegionTipShow={(event, label, code) => {
        if (dataSource[code]) {
          label.html(label.html() + " (Network Traffic - " + numeral(dataSource[code]).format("0.00b") + ")");
        }
      }}
    />
  );
});

export default WorldMap
