import React, { Component, Fragment } from "react";
import { Form } from "antd";
import FormCore from "./FormCore";
import DraggableModal from "./DraggableModal";

/**
 * Brings a form inside a modal, it's intended to be used on quick forms in datagrids to update/create objects
 * 
 * ADVICE: TO USE THIS COMPONENT IT'S REQUIRED THAT YOU ADD THE TAG DraggableModalProvider IN THE ROOT OF YOUT APPLICATION
 * WRAPPING ALL THE CONTENT SO THE MODULE CAN CALCULATE THE Z-INDEX AND POSITION OF THE MODAL
 * 
 * @param { object } formConfig The form object used by the AntDesign to validate and gather the values of the form
 * @param { object } layout The fields layout, it's applied to all of them
 * @param { function } onSubmit The callback of the success submission
 * @param { boolean } visible If the modal is being shown
 * @param { object } titlePrefix The Prefix to the title (recomended: Add / Update)
 * @param { object } title The name of the element represented inside the modal ( User, group, etc... )
 * @param { string } saveText The text inside the submit button
 * @param { string } cancelText The text inside the cancel button
 * @param { function } onSave The function that handles the success submission
 * @param { function } onCancel Cancel action
 */

class FormModal extends Component {
  setErrors = (errors, values) => {
    let fields = {};
    console.log(errors);
    errors.map((error) => {
      fields[error.field] = {
        value: values[error.field],
        errors: error.errors.map((item) => new Error(item))
      };
    });
    this.props.form.setFields(fields);
  }
  onSave = () => {
    const { form, onSave } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        onSave(values, (errors) => this.setErrors(errors, values));
      }
    });
  }

  render() {
    const { loading, dataSource, form, formConfig, visible, lockForm, title, titlePrefix, onCancel, saveText, cancelText, confirmLoading, destroyOnClose, okButtonProps, footer, children, childrenVisible } = this.props;
    const childrenContent = () => {
      if (childrenVisible) {
        return children;
      }
      return null;
    }
    return <DraggableModal
      visible={visible}
      title={<Fragment>{titlePrefix} {title}</Fragment>}
      onOk={this.onSave}
      onCancel={onCancel}
      okText={saveText}
      cancelText={cancelText}
      confirmLoading={confirmLoading}
      destroyOnClose={destroyOnClose}
      okButtonProps={okButtonProps}
      footer={footer}
    >
      {visible && <FormCore
        dataSource={dataSource}
        form={form}
        formConfig={formConfig}
        loading={loading}
        lockForm={lockForm}
        onSubmit={this.onSave} />
      }
      { childrenContent() }
    </DraggableModal>
  }
}

FormModal.defaultProps = {
  cancelText: "Cancel",
  dataSource: {},
  formConfig: { errors: null, fields: null, layout: null },
  loading: false,
  onSave: null,
  onCancel: null,
  saveText: "Save",
  title: "new registry",
  titlePrefix: "Add",
  visible: false,
  lockForm: false,
  confirmLoading: false,
  destroyOnClose: false,
  okButtonProps: null
};

export default Form.create({})(FormModal);
