import React, { Component } from "react";
import { Col, Icon, Row, Select, Spin, Steps, message } from "antd";
import { FormHandler } from "/bbui/components";
import { Link } from "react-router-dom";
import AxiosFormData from "/helpers/AxiosFormData";
import { FormattedMessage, injectIntl } from "react-intl";
import validator from "validator";
import Axios from "axios";

class Wizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      interfaces: [],
      ethInit: "",
      loading: false,
      timezones: [],
      formLicense: null,
      formSystem: null,
      formNetwork: null,
      formAdmin: null,
    };
  }

  getInterfaces() {
    Axios.get("/ajax/wizard.php?act=getInterfacesWizard").then((res) => {
      const items = res.data.items.map((item) => {
        return { value: item.id, text: item.text };
      });
      this.setState({ interfaces: items });
    });
  }

  getTimezones() {
    Axios.get("/ajax/timezones.php").then((res) => {
      if (res.data.length > 0) {
        const timezones = res.data;
        this.setState({ timezones });
      }
    });
  }

  checkRePass = (rule, value, callback, form, setErrors) => {
    const { intl } = this.props;
    const passFieldValue = form.getFieldsValue()["adm_users-pass"];
    if (value && value !== passFieldValue) {
      setErrors([
        {
          field: "adm_users-repass",
          errors: [intl.formatMessage({ id: "wizard.admin.repass.mismatch" })],
        },
      ]);
    }
  };

  checkPassword = (rule, value, callback, form) => {
    if (value) {
      form.validateFields(["adm_users-repass"], { force: true });
    }
  };

  handleNext = () => {
    let { current } = this.state;
    current++;
    this.setState({ current });
    if (current == 1) this.getTimezones();
    if (current == 2) this.getInterfaces();
  };

  handlePrev = () => {
    let { current } = this.state;
    current--;
    this.setState({ current });
  };

  handleSubmitLicense = (values) => {
    const { intl } = this.props;
    const { formLicense } = this.state;
    if (values["wizard-accept-terms"]) {
      this.setState({ loading: true });
      const formData = new AxiosFormData({ act: "stepOne" });
      Axios.post("/ajax/wizard.php", formData).then((res) => {
        if (res.data[0].success == "1") {
          this.handleNext();
          this.setState({ loading: false });
        }
      });
    } else {
      formLicense.setFields({
        ["wizard-accept-terms"]: {
          value: values["wizard-accept-terms"],
          errors: [
            new Error(
              intl.formatMessage({ id: "wizard.license.accepted.error" })
            ),
          ],
        },
      });
      this.setState({ loading: false });
    }
  };

  handleSubmitSystem = (values) => {
    const { intl } = this.props;
    const { formSystem } = this.state;

    this.setState({ loading: true });
    const formData = new AxiosFormData({ act: "stepTwo", ...values });
    Axios.post("/ajax/wizard.php", formData).then((res) => {
      if (res.data[0].success == "1") {
        this.handleNext();
        this.setState({ loading: false });
      } else {
        res.data.forEach((erro) => {
          formSystem.setFields({
            [erro.field]: {
              value: values[erro.field],
              errors: [
                new Error(intl.formatMessage({ id: "common.invalid.value" })),
              ],
            },
          });
          this.setState({ loading: false });
        });
      }
    });
  };

  handleSubmitNetwork = (values) => {
    const { intl } = this.props;
    const { formNetwork } = this.state;

    this.setState({
      loading: true,
      ipnet: values["wizard-network-ip"],
      eth: values["wizard-device-key"],
    });

    const formData = new AxiosFormData({ act: "stepThree", ...values });
    Axios.post("/ajax/wizard.php", formData).then((res) => {
      if (res.data[0].success == "1") {
        this.handleNext();
        this.setState({ loading: false });
      } else {
        res.data.forEach((erro) => {
          formNetwork.setFields({
            [erro.field]: {
              value: values[erro.field],
              errors: [
                new Error(intl.formatMessage({ id: "common.invalid.value" })),
              ],
            },
          });
          this.setState({ loading: false });
        });
      }
    });
  };

  handleSubmitAdmin = (values) => {
    const { intl, history } = this.props;
    const { formAdmin } = this.state;

    this.setState({ loading: true });
    const formData = new AxiosFormData({
      act: "stepFour",
      eth: this.state.eth,
      ...values,
    });
    //Axios.post("/ajax/wizard.php", formData);
    //window.location.href = "https://"+ this.state.ipnet;
    Axios.post("/ajax/wizard.php", formData).then((res) => {
      let idMessage = "common.invalid.value";

      if (values["adm_users-pass"] !== values["adm_users-repass"]) {
        idMessage = "common.wizard.admin.repass.mismatch";
      }

      if (res.data[0].success == "1") {
        window.location.href = "https://" + this.state.ipnet;
      } else {
        res.data.forEach((erro) => {
          formAdmin.setFields({
            [erro.field]: {
              value: values[erro.field],
              errors: [new Error(intl.formatMessage({ id: idMessage }))],
            },
          });
          this.setState({ loading: false });
        });
      }
    });
  };

  validateAddr = (rule, value, cb) => {
    const { intl } = this.props;

    if (!validator.isIP(value, [4])) {
      return cb(
        intl.formatMessage(
          { id: "common.error.invalid" },
          { name: intl.formatMessage({ id: "objects.address.modal.form.ip" }) }
        )
      );
    } else {
      return cb();
    }
  };

  validateMac = (rule, value, cb) => {
    const { intl } = this.props;

    if (!validator.isMACAddress(value)) {
      return cb(
        intl.formatMessage(
          { id: "common.error.invalid" },
          { name: intl.formatMessage({ id: "objects.address.modal.form.ip" }) }
        )
      );
    } else {
      return cb();
    }
  };

  render() {
    const image = require("/public/img/logo-blockbit-new.svg");
    const Step = Steps.Step;
    const { intl } = this.props;
    const { current, interfaces, loading, timezones } = this.state;
    let language = window.localStorage.getItem("language") !== null ? window.localStorage.getItem("language") : (navigator.language.startsWith("pt", 0, 2) ? "pt-BR" : (navigator.language.startsWith("en", 0, 2) ? "en-US" : "es-ES"));

    return (
      <div id="wizard">
        <Spin
          indicator={<Icon type="loading" style={{ fontSize: 50 }} spin />}
          spinning={loading}
        >
          <header
            className="header"
            style={{ height: "72px", marginBottom: "20px" }}
          >
            <Link to="/" className="logo">
              <img
                style={{ height: "38px" }}
                src={image}
                alt="BLOCKBIT GSM"
                title="Powered by BLOCKBIT"
              />
            </Link>
          </header>
          <div className="page-title">
            <h2>
              <FormattedMessage id="wizard.title" />
            </h2>
            <Select
              style={{ float: "right" }}
              defaultValue={language}
              onChange={this.props.onChangeLang}
            >
              <Select.Option value="en-US">English US</Select.Option>
              <Select.Option value="pt-BR">Português BR</Select.Option>
              <Select.Option value="es-ES">Espanhol</Select.Option>
            </Select>
          </div>
          <div className="content">
            <Row style={{ marginBottom: "20px" }}>
              <Col span={18} offset={3}>
                <Steps current={current}>
                  <Step
                    title={intl.formatMessage({ id: "wizard.tabs.license" })}
                  />
                  <Step
                    title={intl.formatMessage({ id: "wizard.tabs.system" })}
                  />
                  <Step
                    title={intl.formatMessage({ id: "wizard.tabs.network" })}
                  />
                  <Step
                    title={intl.formatMessage({ id: "wizard.tabs.admin" })}
                  />
                </Steps>
              </Col>
            </Row>
            <Row>
              <Col span={12} offset={6}>
                {
                  <FormHandler
                    wrappedComponentRef={(inst) =>
                      inst && (this.state.formLicense = inst.props.form)
                    }
                    onSubmit={this.handleSubmitLicense}
                    visible={current == 0}
                    buttons={[
                      /* {
                    submit: true,
                    content: <span><FormattedMessage id="wizard.progress.step1" /></span>,
                    type: "default",
                    float: "left",
                    size: "large"
                  }, */
                      {
                        submit: true,
                        content: (
                          <span>
                            <FormattedMessage id="common.next" />{" "}
                            <Icon type="right" />
                          </span>
                        ),
                        type: "primary",
                        size: "large",
                        float: "right",
                      },
                    ]}
                    formConfig={{
                      fields: [
                        {
                          type: "checkbox_block",
                          name: "wizard-accept-terms",
                          label: intl.formatMessage({
                            id: "wizard.license.accepted",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.license.accepted.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "textarea",
                          name: "agreement",
                          initialValue: intl.formatMessage({
                            id: "wizard.agreement",
                          }),
                          readOnly: true,
                          rows: 15,
                        },
                      ],
                    }}
                  />
                }
                {
                  <FormHandler
                    wrappedComponentRef={(inst) =>
                      inst && (this.state.formSystem = inst.props.form)
                    }
                    visible={current == 1}
                    onSubmit={this.handleSubmitSystem}
                    buttons={[
                      {
                        content: (
                          <span>
                            <Icon type="left" />{" "}
                            <FormattedMessage id="common.prev" />
                          </span>
                        ),
                        type: "primary",
                        float: "right",
                        size: "large",
                        onClick: this.handlePrev,
                      },
                      {
                        submit: true,
                        content: (
                          <span>
                            <FormattedMessage id="common.next" />{" "}
                            <Icon type="right" />
                          </span>
                        ),
                        type: "primary",
                        float: "right",
                        size: "large",
                      },
                    ]}
                    formConfig={{
                      fields: [
                        {
                          type: "text",
                          name: "wizard-hostname",
                          maxLength: 63,
                          label: intl.formatMessage({
                            id: "wizard.system.hostname",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.system.hostname.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "text",
                          name: "wizard-domain",
                          maxLength: 63,
                          label: intl.formatMessage({
                            id: "wizard.system.domain",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.system.domain.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "select",
                          name: "wizard-timezone",
                          label: intl.formatMessage({ id: "common.timezone" }),
                          showSearch: true,
                          placeholder: intl.formatMessage({
                            id: "system.general.timezone.select",
                          }),
                          optionFilterProp: "children",
                          filterOption: (input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0,
                          options: timezones,
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.system.timezone.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "select",
                          options: [
                            { value: "en_US", text: "English US" },
                            { value: "pt_BR", text: "Português BR" },
                            { value: "es_ES", text: "Español" },
                          ],
                          initialValue: "en_US",
                          name: "wizard-language",
                          label: intl.formatMessage({
                            id: "wizard.system.language",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.system.language.error",
                              }),
                            },
                          ],
                        },
                      ],
                    }}
                  />
                }
                {
                  <FormHandler
                    wrappedComponentRef={(inst) =>
                      inst && (this.state.formNetwork = inst.props.form)
                    }
                    visible={current == 2}
                    onSubmit={this.handleSubmitNetwork}
                    buttons={[
                      {
                        content: (
                          <span>
                            <Icon type="left" />{" "}
                            <FormattedMessage id="common.prev" />
                          </span>
                        ),
                        type: "primary",
                        float: "right",
                        size: "large",
                        onClick: this.handlePrev,
                      },
                      {
                        submit: true,
                        content: (
                          <span>
                            <FormattedMessage id="common.next" />{" "}
                            <Icon type="right" />
                          </span>
                        ),
                        type: "primary",
                        float: "right",
                        size: "large",
                      },
                    ]}
                    formConfig={{
                      fields: [
                        {
                          type: "select",
                          name: "wizard-device-key",
                          options: interfaces,
                          label: intl.formatMessage({
                            id: "wizard.network.deviceKey",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.network.deviceKey.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "text",
                          name: "wizard-network-ip",
                          maxLength: 32,
                          label: intl.formatMessage({
                            id: "wizard.network.ip",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.network.ip.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "text",
                          name: "wizard-network-mask",
                          maxLength: 32,
                          label: intl.formatMessage({
                            id: "wizard.network.mask",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.network.mask.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "text",
                          name: "wizard-network-gw",
                          maxLength: 32,
                          label: intl.formatMessage({
                            id: "wizard.network.gateway",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.network.gateway.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "text",
                          name: "wizard-dns1",
                          maxLength: 100,
                          label: intl.formatMessage({
                            id: "common.wizard.network.dns1",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "common.wizard.network.dns1.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "text",
                          name: "wizard-dns2",
                          maxLength: 100,
                          label: intl.formatMessage({
                            id: "common.wizard.network.dns2",
                          }),
                        },
                        {
                          type: "text",
                          name: "wizard-ntp1",
                          maxLength: 100,
                          label: intl.formatMessage({
                            id: "network.general.form.ntp1",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.network.ntp.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "text",
                          name: "wizard-ntp2",
                          maxLength: 100,
                          label: intl.formatMessage({
                            id: "network.general.form.ntp2",
                          }),
                        },
                      ],
                    }}
                  />
                }
                {
                  <FormHandler
                    wrappedComponentRef={(inst) =>
                      inst && (this.state.formAdmin = inst.props.form)
                    }
                    onSubmit={this.handleSubmitAdmin}
                    buttons={[
                      {
                        content: (
                          <span>
                            <Icon type="left" />{" "}
                            <FormattedMessage id="common.prev" />
                          </span>
                        ),
                        type: "primary",
                        size: "large",
                        onClick: this.handlePrev,
                      },
                      {
                        submit: true,
                        content: (
                          <span>
                            <FormattedMessage id="common.save" />
                          </span>
                        ),
                        type: "primary",
                        size: "large",
                      },
                    ]}
                    visible={current == 3}
                    formConfig={{
                      fields: [
                        {
                          type: "text",
                          name: "adm_users-name",
                          maxLength: 100,
                          label: intl.formatMessage({
                            id: "wizard.admin.name",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.admin.name.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "text",
                          name: "adm_users-email",
                          maxLength: 100,
                          label: intl.formatMessage({
                            id: "wizard.admin.email",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.admin.email.error",
                              }),
                            },
                            {
                              type: "email",
                              message: intl.formatMessage({
                                id: "wizard.admin.email.invalid",
                              }),
                            },
                          ],
                        },
                        {
                          type: "password",
                          name: "adm_users-pass",
                          maxLength: 100,
                          label: intl.formatMessage({
                            id: "wizard.admin.pass",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.admin.pass.error",
                              }),
                            },
                          ],
                        },
                        {
                          type: "password",
                          name: "adm_users-repass",
                          label: intl.formatMessage({
                            id: "wizard.admin.repass",
                          }),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: "wizard.admin.repass.error",
                              }),
                            },
                          ],
                        },
                      ],
                    }}
                  />
                }
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
}

export default injectIntl(Wizard);
