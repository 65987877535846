/**
 * Datagrid modal with row selections, pagination, filter and actions buttons
 * @version 1.0
 *
 * @param {array} bulkActions Action buttons listed inside a dropdown at the top right of the grid
 * @param {array} columns Columns specifications, with the refered column, name and render function (if needed)
 * @param {array} dataSource Data of to be shown on the datagrid
 * @param {boolean} filter If the form should show the default filter form at the top
 * @param {object} filterForm Custom filter form to be displayed at the top, the fields must be specified
 * @param {string} id The id of the datagrid, used to store the page size selected
 * @param {function} init This function is called after the component is mounted just to send page size to the parent component
 * @param {string} rowKey Property of datagrid to be used as row rowKey
 * @param {object} labels Key value object with the labels used in the datagrid
 * @param {boolean} loading If it's loading the datagrid info
 * @param {function} rowSelection The row selection function callback ( this argument is required to make multiple selections )
 * @param {object} pagination Pagination specification like in the Pagination object from AntDesign, if defined as false the pagination will not be applied
 * @param {number} total Total items inside the datagrid to be show in the left bottom part of the table
 * @param {string} className Aditional Class Name
 * @param {object} controls Custom controls above datagrid
 * @param {number} scroll Scrollable size of datagrid content
 * @param {object} expandedRow expandedRowRender
 */
import React, { Component, Fragment } from "react";
import {
  Button,
  Form,
  Input,
  Popover,
  Select,
  Table,
  Tooltip,
  DatePicker,
  Row,
  Col
} from "antd";
import { injectIntl } from "react-intl";
import { BulkActionsMenu } from "./";
const { RangePicker } = DatePicker;
import FormCore from "./FormCore";
import moment from "moment";

class Datagrid extends Component {
  constructor(props) {
    super(props);

    this.defaultPageSize = 10;

    this.state = {
      filters: "",
      pageSize: 10,
      filter_range: { dtinit: "", dtend: "" },
      date_filter_type: "none"
    };
  }

  componentDidMount() {
    // Retrieves the page size from the browser storage and sends to the parent component
    const { id, init } = this.props;
    if (init) {
      let pageSize = parseInt(
        window.localStorage.getItem(`bbui_datagrid_pageSize_${id}`)
      );

      init(pageSize ? pageSize : this.defaultPageSize);
    }
  }

  getPageSize = () => {
    if (Storage !== undefined) {
      const { id } = this.props;
      if (id !== "") {
        return parseInt(
          window.localStorage.getItem(`bbui_datagrid_pageSize_${id}`)
        );
      } else {
        console.warn(
          "BBUI Datagrid: The datagrid id wasn't specified, the page size will not be saved in user's browser"
        );
      }
    }

    return this.defaultPageSize;
  };

  handleChange = (pagination, f, sorter) => {
    const { filters, filter_range } = this.state;
    if (Storage !== undefined) {
      const { id } = this.props;
      if (id !== "") {
        window.localStorage.setItem(
          `bbui_datagrid_pageSize_${id}`,
          pagination.pageSize
        );
      }
    }
    if (typeof this.props.onChange == "function")
      this.props.onChange(pagination, filters, sorter, filter_range);
  };

  handleFilter = value => {
    let { pagination } = this.props;
    pagination.current = 1;
    this.setState({ filters: value }, () => this.handleChange(pagination));
  };

  filterFormSetErrors = (errors, values) => {
    let fields = {};
    errors.map(error => {
      fields[error.field] = {
        value: values[error.field],
        errors: error.errors.map(item => new Error(item))
      };
    });
    this.props.form.setFields(fields);
  };

  filterFormOnSubmit = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.props.filterForm.onSubmit(values, errors =>
          this.filterFormSetErrors(errors, values)
        );
      }
    });
  };

  renderActions(item) {
    const { actions, rowKey } = this.props;
    if (!actions) {
      console.error("BB-UI error: Datagrid: No actions buttons defined");
      return <div />;
    }

    return (
      <div className="ui-datagrid-actions" key={`action_${item[rowKey]}`}>
        {actions.map(action => {
          return (
            <Tooltip
              key={`tooltip_${action.text}_${item[rowKey]}`}
              placement="top"
              title={action.text}
            >
              <Button
                onClick={() => action.onClick(item)}
                size="small"
                className="ant-btn-icon-only"
              >
                <i className={action.icon} />
              </Button>
            </Tooltip>
          );
        })}
      </div>
    );
  }

  handleSelectDateFilter = date_filter_type => {
    let callback = null;
    let filter_range = this.state.filter_range;
    let { pagination } = this.props;
    pagination.current = 1;

    if (
      [ 
        "today",
        "yesterday",
        "last_7",
        "last_30",
        "this_month",
        "last_month"
      ].includes(date_filter_type)
    ) {
      const dateFormat = "DD/MM/YYYY";
      let ranges = {
        today: [
          moment().format(dateFormat + " 00:00"),
          moment().format(dateFormat + " 23:59")
        ],
        yesterday: [
          moment()
            .subtract(1, "days")
            .format(dateFormat + " 00:00"),
          moment()
            .subtract(1, "days")
            .format(dateFormat + " 23:59")
        ],
        last_7: [
          moment()
            .subtract(6, "days")
            .format(dateFormat + " 00:00"),
          moment().format(dateFormat + " 23:59")
        ],
        last_30: [
          moment()
            .subtract(29, "days")
            .format(dateFormat + " 00:00"),
          moment().format(dateFormat + " 23:59")
        ],
        this_month: [
          moment()
            .startOf("month")
            .format(dateFormat + " 00:00"),
          moment()
            .endOf("month")
            .format(dateFormat + " 23:59")
        ],
        last_month: [
          moment()
            .subtract(1, "month")
            .startOf("month")
            .format(dateFormat + " 00:00"),
          moment()
            .subtract(1, "month")
            .endOf("month")
            .format(dateFormat + " 23:59")
        ]
      };

      filter_range = {
        dtinit: ranges[date_filter_type][0],
        dtend: ranges[date_filter_type][1]
      };
      callback = () => this.handleChange(pagination);
    } else if (date_filter_type === "none") {
      filter_range = { dtinit: "", dtend: "" };
      callback = () => this.handleChange(pagination);
    }

    this.setState({ filter_range: filter_range, date_filter_type }, callback);
  };

  handleRangeFilter = (dateObject, dateString) => {
    let { pagination } = this.props;
    pagination.current = 1;
    this.setState(
      {
        filter_range: { dtinit: dateString[0], dtend: dateString[1] }
      },
      () => this.handleChange(pagination)
    );
  };

  handleMonthFilter = (dateObject, dateString) => {
    console.log("handleMonthFilter", dateString);
    let { pagination } = this.props;
    pagination.current = 1;
    let range =
      this.state.date_filter_type === "none"
        ? { dtinit: "", dtend: "" }
        : { dtinit: dateString, dtend: dateString };
    this.setState(
      {
        filter_range: range
      },
      () => this.handleChange(pagination)
    );
  };

  /* Renders the button that shows the default filter form */
  renderFilterDate() {
    return (
      <Popover
        placement="bottomRight"
        content={this.renderFilterContentDate()}
        trigger="click"
      >
        <Button className="bbui-action-button">
          <i className="fa fa-calendar"></i>
        </Button>
      </Popover>
    );
  }

  /* Render the default filter form at the top of datagrid */
  renderFilterContent(total = 0) {
    const { Search } = Input;
    return (
      <div className="ui-datagrid-searchbar">
        <Search
          addonBefore={
            total > 0 ? `${total} ${this.props.labels.records}` : undefined
          }
          onSearch={this.handleFilter}
          maxLength={253}
          allowClear
          enterButton
        />
      </div>
    );
  }

  renderFilterContentDate() {
    const { date_filter_type } = this.state;
    return (
      <div style={{ width: 256 }}>
        {this.props.intl.formatMessage({ id: "analytics.reports.period" })}: &nbsp;{" "}
        <Select
          onChange={this.handleSelectDateFilter}
          style={{ width: "100%" }}
          value={date_filter_type}
        >
          <Select.Option value={"none"}>{this.props.intl.formatMessage({ id: "common.all" })}</Select.Option>
          <Select.Option value={"date"}>{this.props.intl.formatMessage({ id: "events.date" })}</Select.Option>
          <Select.Option value={"range"}>{this.props.intl.formatMessage({ id: "events.range" })}</Select.Option>
          <Select.Option value={"today"}>{this.props.intl.formatMessage({ id: "events.today" })}</Select.Option>
          <Select.Option value={"yesterday"}>{this.props.intl.formatMessage({ id: "events.yesterday" })}</Select.Option>
          <Select.Option value={"last_7"}>{this.props.intl.formatMessage({ id: "events.last_7" })}</Select.Option>
          <Select.Option value={"last_30"}>{this.props.intl.formatMessage({ id: "events.last_30" })}</Select.Option>
          <Select.Option value={"this_month"}>{this.props.intl.formatMessage({ id: "events.this_month" })}</Select.Option>
          <Select.Option value={"last_month"}>{this.props.intl.formatMessage({ id: "events.last_month" })}</Select.Option>
        </Select>
        {["date", "range"].includes(date_filter_type) && (
          <span>
            <br />
            <br />
            {date_filter_type === "date" && (
              <DatePicker
                size={"default"}
                onChange={this.handleMonthFilter}
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
              />
            )}
            {date_filter_type === "range" && (
              <RangePicker
                size={"default"}
                onChange={this.handleRangeFilter}
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
              />
            )}
          </span>
        )}
      </div>
    );
  }

  /* Render the custom filter form */
  renderFilterForm() {
    const { fields, dataSource } = this.props.filterForm;
    return (
      <Fragment>
        <FormCore
          className="ui-datagrid-advanced-filter"
          dataSource={dataSource}
          form={this.props.form}
          formConfig={{
            layout: { colon: false, layout: "inline" },
            fields: fields
          }}
          onSubmit={this.filterFormOnSubmit}
        />
        <Button
          style={{ top: 29 }}
          type="primary"
          onClick={this.filterFormOnSubmit}
        >
          <span className="fa fa-search"></span>
        </Button>
      </Fragment>
    );
  }

  renderGridTitle() {
    const { gridTitle } = this.props;

    return (
      <div style={{ float: "left" }}>
        <span>
          <h4>{gridTitle}</h4>
        </span>
      </div>
    );
  }

  render() {
    const {
      bulkActions,
      className,
      controls,
      scroll,
      columns,
      dataSource,
      filter,
      filterForm,
      filterDate,
      labels,
      loading,
      rowKey,
      onSelectAll,
      pagination,
      rowSelection,
      gridTitle,
      total,
      plusActions
    } = this.props;

    columns.map((column, index) => {
      if (column.actions === true) {
        columns[index].render = item => this.renderActions(item);
      }
    });

    const pageSize = this.getPageSize();
    let pagDefaultConf = false;
    if (pagination !== false) {
      let showSizeChanger = this.props.showSizeChanger
        ? this.props.showSizeChanger
        : true;
      pagDefaultConf = {
        total: total,
        defaultPageSize: pageSize,
        showSizeChanger: showSizeChanger,
        ...pagination
      };
    }

    if(pagDefaultConf.total <= 0) { pagDefaultConf.total = -(pagDefaultConf.total) }

    return (
      <Fragment>
        {(gridTitle || filterForm || filter || controls || bulkActions || plusActions ) && (
          <div className="ui-datagrid-controls">
            {total > 0 && !filter && filterForm && (
              <span className="ui-datagrid-total">
                {labels.total}: {total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}
              </span>
            )}
            {gridTitle && this.renderGridTitle()}
            {filter && !filterForm && this.renderFilterContent(total)}
            {!filter && filterForm && this.renderFilterForm()}
            {filterDate && this.renderFilterDate()}
            {controls}
            {plusActions && <BulkActionsMenu icon='fa fa-plus' actions={plusActions} />}
            {bulkActions && <BulkActionsMenu actions={bulkActions} />}
          </div>
        )}
        <Table
          className={"ui-datagrid " + className}
          scroll={scroll}
          columns={columns}
          dataSource={dataSource}
          indentSize={0}
          loading={loading}
          rowSelection={rowSelection}
          onChange={this.handleChange}
          rowKey={rowKey}
          pagination={pagDefaultConf}
          expandedRowRender={this.props.expandedRowRender}
          {...(this.props.expandedRowKeys && { expandedRowKeys: this.props.expandedRowKeys })}
          onExpand={this.props.onExpand}
          locale={this.props.locale}
          size="small"
        />
      </Fragment>
    );
  }
}

Datagrid.defaultProps = {
  bulkActions: null,
  className: "",
  controls: null,
  scroll: {},
  columns: [],
  dataSource: [],
  filter: false,
  filterDate: false,
  filterForm: null,
  id: "",
  init: null,
  rowKey: "id",
  labels: { total: "Total", records: "records" },
  loading: false,
  rowSelection: null,
  onChangePage: null,
  onSelectAll: null,
  pagination: {},
  expandedRowKeys: null,
  total: 0
};

export default injectIntl(Form.create({})(Datagrid));
