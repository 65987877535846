import React, { Component, Fragment } from "react";
import { Col, Form, Row, Tooltip } from "antd";
import FormCore from "./FormCore";
import ActionButton from "./ActionButton";

/**
 * Standalone form component, will submit the data and pass the callback to the parent modules
 * 
 * @param { object } formConfig The form object used by the AntDesign to validate and gather the values of the form
 * @param { object } layout The fields layout, it's applied to all of them
 * @param { function } onSubmit The callback of the success submission
 */

class FormHandler extends Component {
  setErrors = (errors, values) => {
    let fields = {};
    errors.map((error) => {
      fields[error.field] = {
        value: values[error.field],
        errors: error.errors.map((item) => new Error(item))
      };
    });
    this.props.form.setFields(fields);
  }
  onSubmit = (callback = null) => {
    const { form, onSubmit } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        if (typeof (callback) == "object" || callback == null)
          onSubmit(values, (errors) => this.setErrors(errors, values));
        else
          callback(values, (errors) => this.setErrors(errors, values));
      }
    });
  }
  renderUpperButtons() {
    const { lockForm, upperButtons } = this.props;

    return <Row>
      <Col span={24}>
        <div className="ui-datagrid-controls">
          {upperButtons.map((button, i) => {
            if (button.submit == true)
              button.func = this.onSubmit;
            if (button.callback != null)
              button.func = () => this.onSubmit(button.callback);

            return <ActionButton key={i} icon={<i className={button.icon} />} tooltip={button.text} onClick={button.func} />
          })}
        </div>
      </Col>
    </Row>;
  }
  render() {
    const { buttons, dataSource, form, formConfig, colExtra, lockForm, upperButtons, visible, header } = this.props;

    formConfig.fields.map((field, fIndex) => {
      if ("rules" in field && field.rules) {
        field.rules.map((rule, rIndex) => {
          if ("validator" in rule) {
            const originalFunc = rule.validator;
            formConfig.fields[fIndex].rules[rIndex].validator = (rule, value, callback) => {
              originalFunc(rule, value, callback, form, (errors) => this.setErrors(errors, form.getFieldsValue()));
            }
          }
        });
      }
    });


    return <Fragment>
      {upperButtons && this.renderUpperButtons()}
      <Row>
        <Col {...colExtra}>
          <FormCore
            header={header}
            buttons={buttons}
            dataSource={dataSource}
            form={form}
            formConfig={formConfig}
            lockForm={lockForm}
            onSubmit={this.onSubmit}
            visible={visible} />
        </Col>
      </Row>
    </Fragment>;
  }
}

FormHandler.defaultProps = {
  buttons: [],
  fields: [],
  formConfig: [],
  formWidth: 24,
  layout: {},
  lockForm: false,
  onSubmit: null,
  visible: true,
  colExtra: undefined,
  header: [],
}

export default Form.create({})(FormHandler);