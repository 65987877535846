import React, { Component, Fragment } from "react";
import { Button, Form } from "antd";
import FormCore from "./FormCore";
import Tab from "./Tab";

/**
 * Tabbed form component, will submit the data and pass the callback to the parent modules
 * 
 * @param { object } formConfig The form object used by the AntDesign to validate and gather the values of the form
 * @param { object } layout The fields layout, it's applied to all of them
 * @param { boolean } submitButton If the form includes a submit button
 * @param { function } onSubmit The callback of the success submission
 * @param { string } tabPosition Position of the tabs (top, left, bottom and right)
 */

class FormTabs extends Component {
  constructor(props) {
    super(props);
    this.state = { current: 0 };
  }
  componentDidMount() {
    this.props.initValues(this.props.form);
  }
  setErrors = (errors, values) => {
    let fields = {};
    errors.map((error) => {
      fields[error.field] = {
        value: values[error.field],
        errors: error.errors.map((item) => new Error(item))
      };
    });
    this.props.form.setFields(fields);
  }
  onSave = (e) => {
    e.preventDefault();
    const { form, onSave } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        onSave(values, (errors) => this.setErrors(errors, values));
      }
    });
  }
  renderPanes() {
    const { dataSource, form, tabs } = this.props;
    const panes = [];

    const hasDefault = tabs.filter((item) => "default" in item);

    tabs.map((item, index) => {
      if (hasDefault.length == 0 && index == 0)
        item.default = true;

      if ("children" in item && item.children != null) {
        panes.push({
          name: item.title,
          children: item.children,
          url: item.url,
          default: item.default,
          statusTab: item.tabStatus ? item.tabStatus : false
        });
      } else {
        panes.push({
          name: item.title,
          children: <FormCore noFormWrap={true} dataSource={dataSource} form={form} formConfig={item.formConfig} />,
          url: item.url,
          default: item.default,
          statusTab: item.tabStatus ? item.tabStatus : false
        });
      }
    });

    return panes;
  }
  render() {
    const { layout, bottomSpacing, footer, history, tabPosition, type, noBox } = this.props;
    return <Fragment>
      <Form onSubmit={this.onSave} layout={layout}>
        <Tab
          forceRender
          bottomSpacing={bottomSpacing}
          panes={this.renderPanes()}
          history={history}
          tabPosition={tabPosition}
          noBox={noBox}
          type={type} />
          {footer}
      </Form>
    </Fragment>
  }
}

FormTabs.defaultProps = {
  layout: "horizontal",
  bottomSpacing: true,
  footer: null,
  onSubmit: null,
  tabs: [],
  tabPosition: "top",
  type: "",
  noBox: false,
}

export default Form.create({})(FormTabs);