import React, { PureComponent } from "react";
import { default as ApexChart } from "react-apexcharts";

class Chart extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let options = {
      ...this.props.options,
      stroke: {
        ...this.props.options.stroke,
        show: true,
        curve: 'straight',
        lineCap: 'round',
        dashArray: 0,
        width: 1.5,
      }
    }
    return <ApexChart {...this.props} options={ options } />;
  }
}

Chart.defaultProps = {
  options: {},
  series: [],
  type: "area",
  width: "100%",
  height: 220
};

export default Chart;
