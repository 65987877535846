const AxiosFormData = (args) => {
  const formData = new FormData();

  Object.keys(args).map((obj) => {
    if (Array.isArray(args[obj])) {
      if (args[obj].length > 0) {
        args[obj].map( (a,i)=> formData.set(`${obj}[${i}]`, a))
      } else {
        formData.set(`${obj}[]`, '')
      }
    } else {
      formData.set(obj, args[obj])
    }
  });
  return formData;
}

export default AxiosFormData;