import React, { Component, Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Button, Col, DatePicker, Form, Input, Modal, Select, Steps, Row, message } from "antd";
import Axios from "axios";
import moment from "moment-timezone";

import { DraggableModal, FormSteps } from "/bbui/components";

class PackageInstallModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lockForm: false,
      currStep: 0,
      optionsDevices: [],
      optionsPackage: [],
      optionsAssign: [],
      optionsCommunities: []
    };

    this.packageTypes = {
      "policy_pack": "Packages",
      "device_temp": "Templates",
      "device_comm": "Communities",
    };

    this.stepsFields = [
      ['install_package-type', 'install_package-pack'],
      ['install_package-device-list'],
      ['install_package-date-time'],
      ['install_package-assing', 'install_package-comment']
    ];

    this.fieldConfig = { rules: [{ required: true, messsage: this.props.intl.formatMessage({ id: "common.error.required" }, { name: this.props.intl.formatMessage({ id: "common.packages" }) }) }] };

    this.formItemLayout = {
      /*labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },*/
    };
  }

  componentDidMount() {
    this.getTimezone();
  }
  componentWillMount() {
    Axios.post("/ajax/install-package.php?act=getInstallDevices").then((response) => {
      if (Array.isArray(response.data) && response.data.length == 2)
        if (response.data[1]['errorcode'] == '001') {
          this.props.onClose()
          return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
        }

      //if(response.data.length > 0){
      if ("items" in response.data) {
        const { items } = response.data;
        this.setState({ optionsDevices: items });
      }
    });
    Axios.post("/ajax/install-package.php?act=getInstallPackages&type=deploys").then((response) => {
      //if(response.data.length > 0){
      if ("items" in response.data) {
        const { items } = response.data;
        this.setState({ optionsPackage: items });
      }
    });
    Axios.post("/ajax/install-package.php?act=getInstallAssign").then((response) => {
      //if(response.data.length > 0){
      if ("items" in response.data) {
        const { items } = response.data;
        this.setState({ optionsAssign: items });
      }
    });
  }

  disabledDate = (current) => {
    return current && moment().isAfter(current, 'day');
  }

  disabledTime = (current) => {
    if (current && moment().isSame(current, 'day')) {
      const disabledHours = [...Array(parseInt(moment().format("H"))).keys()];
      let disabledMinutes = [];
      const disabledSeconds = [...Array(59).keys()];

      if (current && moment().isSame(current, "hour"))
        disabledMinutes = [...Array(parseInt(moment().format("m"))).keys()];

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: () => disabledMinutes,
        disabledSeconds: () => disabledSeconds,
      }
    }
  }
  handleModalClose = () => {
    this.props.onClose();
  }

  handleAssignToMe = (value) => {
    Axios.get("/ajax/install-package.php?act=getMyUser").then((response) => {
      const currentUser = response.data[0].id;
      this.props.form.setFieldsValue({
        "install_package-assing": currentUser.toString()
      })
    });
  }

  handleChangePackType = (value, name) => {
    const type = this.packageTypes[value];
    this.setState({ optionsPackage: [], optionsCommunities: [] });
    this.props.form.setFieldsValue({
      "install_package-pack": ""
    })

    Axios.get("/ajax/install-package.php?act=getInstall" + type + "&type=deploys").then((response) => {
      if ("items" in response.data) {
        const { items } = response.data;
        this.setState({ optionsPackage: items });
        this.props.form.setFieldsValue({
          "install_package-type": value
        });
      }
    });
  }

  handleTaskCommunity = (community_id) => {

    if (this.props.form.getFieldValue("install_package-type") == "device_comm") {
      Axios.get("/ajax/install-package.php?act=getTaskDeviceCommunity&community_id=" + community_id).then((response) => {
        if (response.data) {
          this.setState({ optionsCommunities: response.data.map(i => "device^" + i.id) });
        }
      });
    }
  }

  handleDateChange = (dateObj, dateString) => {
    this.props.form.setFields({
      "install_package-date-time": { value: dateString, errors: [] }
    });
    this.props.form.validateFields(["date-time"]);
  }

  handleStepChange = (next = true) => {
    let { currStep } = this.state;
    const { form } = this.props;
    const { validateFields } = form;

    if (next && currStep < 3) {
      validateFields(this.stepsFields[currStep], (errors, values) => {
        if (errors == null) currStep++;
      });
    } else {
      if (!next && currStep > 0)
        currStep--;
    }
    this.setState({ currStep });
  }

  handleSubmit = () => {
    const { intl, form } = this.props;
    const values = form.getFieldsValue();
    const { validateFields } = form;
    let { currStep } = this.state;

    this.setState({ lockForm: true });

    validateFields(this.stepsFields[currStep], (errors) => {
      if (!errors) {
        const bodyData = new FormData();
        bodyData.set("act", "applyPackage");
        bodyData.set("install_package-type", values["install_package-type"]);
        bodyData.set("install_package-pack", values["install_package-pack"]);
        bodyData.set("install_package-date-time", moment(values["install_package-date-time"]).format("YYYY-MM-DD HH:mm"));
        bodyData.set("install_package-assing", values["install_package-assing"]);
        bodyData.set("install_package-comment", values["install_package-comment"]);
        bodyData.set("install_package-device-list", values["install_package-device-list"]);

        Axios.post("/ajax/install-package.php", bodyData).then((res) => {
          if (Array.isArray(res.data) && res.data.length == 2)
            if (res.data[1]['errorcode'] == '001') {
              this.handleModalClose();
              this.setState({ lockForm: false });
              return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
            }

          if (res.data[0].success == 1) {
            message.success(intl.formatMessage({ id: "deploys.create.success" }));
            this.handleModalClose();
          } else {
            message.error(intl.formatMessage({ id: "deploys.create.error" }));
          }

          this.setState({ lockForm: false });
        }).catch((error) => {
          this.setState({ lockForm: false });
          message.error(this.props.intl.formatMessage({ id: 'common.error.network' }));
        })
      } else {
        this.setState({ lockForm: false });
      }
    });
  }

  getTimezone = () => {
    const bodyData = new FormData();
    bodyData.set("act", "getEditSettings");
    bodyData.set("tab", "general");

    Axios.post("/ajax/settings-system.php", bodyData).then((res) => {
      if (res.data) {
        moment.tz.setDefault(res.data[0]["timezone"]);
      }
    });
  }

  renderStep0() {
    const { intl } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { currStep, optionsPackage } = this.state;

    let display = "none";
    if (currStep == 0) display = "block"

    return <div style={{ display }}>
      <Form.Item {...this.formItemLayout} label={intl.formatMessage({ id: "packages.packageType" })}>
        {getFieldDecorator("install_package-type", { rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "packages.packageType" }) }) }] })(
          <Select onChange={this.handleChangePackType}>
            <Select.Option value="policy_pack">{intl.formatMessage({ id: "common.packages.combo" })}</Select.Option>
            <Select.Option value="device_temp">{intl.formatMessage({ id: "common.templates.combo" })}</Select.Option>
            <Select.Option value="device_comm">{intl.formatMessage({ id: "common.community" })}</Select.Option>
          </Select>
        )}
      </Form.Item>

      <Form.Item {...this.formItemLayout} label={intl.formatMessage({ id: "packages.package" })}>
        {getFieldDecorator("install_package-pack", { rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "packages.package" }) }) }] })(
          <Select onChange={this.handleTaskCommunity}>
            {optionsPackage.map((item) => {
              return <Select.Option key={`${item.id}`} value={`${item.id}`}>{item.text}</Select.Option>
            })}
          </Select>
        )}
      </Form.Item>
    </div>;
  }

  renderStep1() {
    const { intl } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { currStep, optionsDevices, optionsCommunities } = this.state;
    let display = "none";
    if (currStep == 1) display = "block"

    return <div style={{ display }}>
      <Form.Item label={intl.formatMessage({ id: "packages.device" })}>
        {getFieldDecorator("install_package-device-list", { initialValue: optionsCommunities, rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "packages.device" }) }) }] })(
          <Select
            disabled={optionsCommunities.length > 0 ? true : false}
            showSearch
            placeholder="Select"
            optionFilterProp="children"
            mode="multiple"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {optionsDevices.map((item) => {
              return <Select.Option key={`${item.id}`} value={`${item.id}`}>{item.text}</Select.Option>
            })}

          </Select>
        )}
      </Form.Item>
    </div>;
  }

  renderStep2() {
    const { intl } = this.props;
    const { currStep } = this.state;
    const { getFieldDecorator } = this.props.form;
    let dateConf = {
      rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "packages.dateTime" }) }) }]
    };

    let display = "none";
    if (currStep == 2)
      display = "block";

    return <div style={{ display }}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label={intl.formatMessage({ id: "packages.dateTime" })} {...this.formItemLayout}>
            {getFieldDecorator("install_package-date-time", dateConf)(
              <DatePicker
                style={{ width: 100 + '%' }}
                onChange={this.handleDateChange}
                disabledDate={this.disabledDate}
                showToday
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm" />
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>;
  }

  renderStep3() {
    const { intl } = this.props;
    const { currStep, optionsAssign } = this.state;
    const { getFieldDecorator } = this.props.form;
    let display = "none";
    if (currStep == 3) display = "block"

    return <div style={{ display }}>
      <Row gutter={16}>
        <Col span={17}>
          <Form.Item label={intl.formatMessage({ id: "packages.assign" })} {...this.formItemLayout}>
            {getFieldDecorator("install_package-assing", { rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "packages.assign" }) }) }] })(
              <Select>
                {optionsAssign.map((item) => {
                  return <Select.Option key={`${item.id}`} value={`${item.id}`}>{item.text}</Select.Option>
                })}
              </Select>
            )}

          </Form.Item>
        </Col>
        <Col span={5}>
          <button style={{ marginTop: 34 }} onClick={this.handleAssignToMe} type="button" className="btn btn-default">{intl.formatMessage({ id: "packages.assign.tome" })}</button>
        </Col>
      </Row>

      <Form.Item label={intl.formatMessage({ id: "packages.comments" })} {...this.formItemLayout}>
        {getFieldDecorator("install_package-comment", { rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "packages.comments" }) }) }] })(
          <Input.TextArea></Input.TextArea>
        )}
      </Form.Item>
    </div>;
  }

  renderFooter() {
    const { currStep, lockForm } = this.state;

    return <Fragment>
      {currStep > 0 &&
        <Button onClick={() => this.handleStepChange(false)} loading={lockForm} key="prev" className="btn btn-default"><FormattedMessage id="common.prev" /></Button>
      }
      {currStep < 3 &&
        <Button onClick={this.handleStepChange} loading={lockForm} key="next" className="btn btn-default"><FormattedMessage id="common.next" /></Button>
      }
      {currStep == 3 &&
        <Button type="save" onClick={this.handleSubmit} loading={lockForm} key="save" className="btn btn-primary"><FormattedMessage id="common.save" /></Button>
      }
    </Fragment>
  }

  render() {
    const { intl } = this.props;
    const { Step } = Steps;
    const { currStep } = this.state;

    return <DraggableModal
      width={650}
      visible={true}
      title={intl.formatMessage({ id: "packages.title" })}
      footer={this.renderFooter()}
      onCancel={this.handleModalClose}>
      <Row>
        <Col span={6}>
          <Steps direction="vertical" current={currStep}>
            <Step title={intl.formatMessage({ id: "packages.what" })} />
            <Step title={intl.formatMessage({ id: "packages.where" })} />
            <Step title={intl.formatMessage({ id: "packages.when" })} />
            <Step title={intl.formatMessage({ id: "packages.who" })} />
          </Steps>
        </Col>
        <Col span={18}>
          <FormSteps />
          <Form onSubmit={this.handleSubmit}>
            {this.renderStep0()}
            {this.renderStep1()}
            {this.renderStep2()}
            {this.renderStep3()}
          </Form>
        </Col>
      </Row>
    </DraggableModal>
  }
}

export default injectIntl(Form.create()(PackageInstallModal));
